import { TOGGLE_STATS } from "../actions/types";
const initialState = {
  showStat: false,
};

const Event = (state = initialState, { type, payload }) => {
  switch (type) {
    case TOGGLE_STATS:
      return { ...state, showStat: !state.showStat };
    default:
      return state;
  }
};

export default Event;
