import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { getCurrentProfile } from "../../actions/profile";
const Index = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCurrentProfile());
  }, []);
  const profile = useSelector((state) => state.profile.profile);
  const FontBold = {
    fontWeight: 600,
  };
  return (
    <div>
      <h1 className="text-primary">Account</h1>
      <div style={{ marginBottom: 20 }}>
        <div style={{ marginTop: 20, fontSize: 20 }}>
          <div>
            <span style={FontBold}>Display Name : </span>{" "}
            {profile?.display_name}{" "}
          </div>
          <div>
            <span style={FontBold}>Name : </span> {profile?.full_name}{" "}
          </div>
          <div>
            <span style={FontBold}>Email : </span> {profile?.email}{" "}
          </div>
        </div>
      </div>
      <p className="my-1">
        <Link to="/edit-profile" style={{ textDecoration: "underline" }}>
          Update Profile
        </Link>
      </p>
      <p className="my-1">
        <Link to="/change-password" style={{ textDecoration: "underline" }}>
          Change Password
        </Link>
      </p>
    </div>
  );
};

export default Index;
