import React, { Fragment, useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import BasicDetailsForm from "./EventForm/BasicDetailsForm";
import TextField from "@mui/material/TextField";
import api from "../../utils/api";
import { Radio } from "antd";
import RecurringEventForm from "./EventForm/RecurringEventForm";

const defaultValues = {
  eventName: "",
  description: "",
  location: "",
  eventStartDateAndTime: new Date(),
  eventDuration: "",
  mediaList: [],
  publishedOn: [],
  Tags: [],
  checkIn: "enabled",
  codeCheckIn: "JuznowSelfCheckIn",
  directions: "",
  locateUs: "",
  City: "",
  OrganizerAlias: "",
  isRecurring: false,
  Recurrence: "Weekly",
  Dayofweek: [],
  startRecurrence: new Date(),
  endRecurrence: new Date(),
  eventTime: new Date(),
  TagsValues: ["speeddating", "social"],
  // eventType: "",
};

export default function CreateEvent() {
  const history = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const { pollId, pollOpId } = useParams();
  const [pollQ, setpollQ] = useState(null);
  const [pollOp, setpollOp] = useState(null);
  useEffect(() => {
    if (pollId) {
      const getPoll = async () => {
        try {
          const res = await api.get(`/poll/${pollId}`);
          setpollQ(res?.data?.poll?.question);
          setpollOp(
            res?.data?.poll?.options.filter((op) => op._id === pollOpId)[0]
              .option
          );
        } catch (err) {
          setErrorMsg("Failed to fetch poll");
        }
      };
      getPoll();
    }
  }, [pollId]);

  const handleFormSubmit = async (data) => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    setErrorMsg(null);
    try {
      if (data.isRecurring) {
        let eventStartDate = new Date(data.eventStartDateAndTime);
        let eventTime = new Date(data.eventTime);

        eventStartDate.setUTCHours(
          eventTime.getUTCHours(),
          eventTime.getUTCMinutes(),
          eventTime.getUTCSeconds()
        );

        data.eventStartDateAndTime = eventStartDate.toISOString();
      }
      let obj = { ...data, pollId, pollOpId };
      const res = await api.post("/events", obj);
      const id = res.data._id;
      setIsSubmitting(false);
      history(`/events/${id}/add-reservation`);
    } catch (err) {
      setErrorMsg("Failed to create event");
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <div className="d-flex my-2 align-items-center">
        {/* {process.env.REACT_APP_POSTSHOW === "true" && (
          <Fragment>
            <div>
              <NavLink
                to={"/posts"}
                className="btn"
                activeClassName="btn-primary"
              >
                Post
              </NavLink>
            </div>
            <div>
              <NavLink
                to={"/events/create"}
                className="btn"
                activeClassName="btn-primary"
                style={{ backgroundColor: "#17a2b8", color: "white" }}
              >
                Event
              </NavLink>
            </div>
          </Fragment>
        )} */}
      </div>
      <section>
        <h1 className="large text-primary mb-4">Create an Event</h1>
      </section>
      <section key="SINGLE_EVENT">
        {isSubmitting ? (
          <div className="alert alert-info">Hold on ...</div>
        ) : null}
        {errorMsg ? <div className="alert alert-danger">{errorMsg}</div> : null}
        {pollId && (
          <div className="mb-3">
            <TextField
              variant="outlined"
              className="form-control mb-3"
              type="text"
              label="Poll Question"
              value={pollQ ?? ""}
              disabled={true}
            />
            <TextField
              key={pollOp}
              variant="outlined"
              className="form-control mb-3"
              type="text"
              label="Poll Option"
              value={pollOp ?? ""}
              disabled={true}
            />
          </div>
        )}

        <BasicDetailsForm
          defaultValues={defaultValues}
          onSubmit={handleFormSubmit}
        />
      </section>
    </div>
  );
}
