import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Website from "./Website";
import api from "../../utils/api";
import { Link } from "react-router-dom";

const Index = () => {
  const history = useNavigate();
  const [WebsitesDta, setWebsitesDta] = useState([]);

  useEffect(() => {
    api
      .get("/websites/my")
      .then((res) => {
        setWebsitesDta(res.data);
      })
      .catch((err) => {
        console.log(err);
        setWebsitesDta([]);
      });
  }, []);
  return (
    <div>
      <section>
        <div className="page-title-back-btn-outer">
          <div className="back-btn  pointer-cursor" onClick={() => history(-1)}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
              <path d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z" />
            </svg>
            Back
          </div>
          <div className="d-flex justify-content-between">
            <div className="page-title">My Websites</div>
            <div
              className="reserve-btn-o"
              style={{
                marginTop: "10px",
              }}
            >
              <Link to={`/website`}>Create Website</Link>
            </div>
          </div>
        </div>
        <div>
          {WebsitesDta.map((website, index) => {
            return <Website key={index} website={website} />;
          })}
        </div>
      </section>
    </div>
  );
};

export default Index;
