import React, { Fragment, useState, useEffect } from "react";
import { Link, useMatch, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createProfile, getCurrentProfile } from "../../actions/profile";
import api from "../../utils/api";
import TextField from "@mui/material/TextField";

/*
  NOTE: declare initialState outside of component
  so that it doesn't trigger a useEffect
  we can then safely use this to construct our profileData
 */
const initialState = {
  display_name: "",
  first_name: "",
  last_name: "",
  phone: "",
  city: "",
  country: "",
  about_me: "",
  gender: "male",
  date_of_birth: {
    day: "",
    month: "",
    year: "",
  },
};

const ProfileForm = ({
  profile: { profile, loading },
  createProfile,
  getCurrentProfile,
}) => {
  const [formData, setFormData] = useState(initialState);
  const creatingProfile = useMatch("/create-profile");
  const navigate = useNavigate();
  const [DisplayNameCheck, setDisplayNameCheck] = useState(null);

  useEffect(() => {
    // if there is no profile, attempt to fetch one
    if (!profile) getCurrentProfile();

    // if we finished loading and we do have a profile
    // then build our profileData
    if (!loading && profile) {
      const profileData = { ...initialState };
      for (const key in profile) {
        if (key in profileData) profileData[key] = profile[key];
      }
      // set local state with the profileData
      setFormData(profileData);
    }
  }, [loading, getCurrentProfile, profile]);
  const {
    display_name,
    first_name,
    last_name,
    phone,
    city,
    country,
    about_me,
    gender,
    date_of_birth,
  } = formData;
  const today = new Date();
  const onChange = (e) => {
    if (e.target.name === "phone") {
      var Number = ("" + e.target.value).replace(/\D/g, "");
      setFormData({ ...formData, [e.target.name]: Number });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    if (e.target.name === "display_name") {
      api
        .post(`auth/validate-displayname`, { display_name: e.target.value })
        .then(({ data }) => {
          setDisplayNameCheck(data);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    createProfile(formData, navigate, profile ? true : false);
  };

  const onChangeDOB = (e) => {
    const d = new Date(e.target.value);
    let day = d.getDate();
    let month = d.getMonth();
    let year = d.getFullYear();
    let obj = {
      day: day,
      month: month,
      year: year,
    };
    setFormData({ ...formData, date_of_birth: obj });
  };

  const TodayDate = () => {
    let day = today.getDate();
    let month = today.getMonth();
    let year = today.getFullYear();
    return `${
      year + "-" + (month + 1 < 10 ? `0${month + 1}` : month + 1) + "-" + day
    }`;
  };
  return (
    <section>
      <h1 className="large text-primary">
        {creatingProfile ? "Create Your Profile" : "Edit Your Profile"}
      </h1>
      <p className="lead">
        <i className="fas fa-user" />
        {creatingProfile
          ? ` Let's get some information to make your profile stand out`
          : " Update your profile"}
      </p>
      <form className="form" onSubmit={onSubmit}>
        <TextField
          variant="outlined"
          className="form-control"
          type="text"
          label="Display Name"
          name="display_name"
          required
          value={display_name}
          onChange={onChange}
        />
        <div>
          {!DisplayNameCheck?.status ? (
            <span style={{ color: "red" }}>{DisplayNameCheck?.message}</span>
          ) : (
            <span style={{ color: "green" }}>Display Name Available </span>
          )}
        </div>
        <div className="form-group">
          <TextField
            variant="outlined"
            className="form-control"
            type="text"
            label="First Name"
            name="first_name"
            required
            value={first_name}
            onChange={onChange}
          />
        </div>
        <div className="form-group">
          <TextField
            variant="outlined"
            className="form-control"
            type="text"
            label="Last Name"
            name="last_name"
            required
            value={last_name}
            onChange={onChange}
          />
        </div>
        <div className="form-group">
          <TextField
            variant="outlined"
            className="form-control"
            type="text"
            label="Phone"
            name="phone"
            required
            value={phone}
            onChange={onChange}
          />
        </div>
        <div className="form-group">
          <TextField
            variant="outlined"
            className="form-control"
            type="text"
            label="City"
            name="city"
            required
            value={city}
            onChange={onChange}
          />
        </div>
        <div className="form-group">
          <TextField
            variant="outlined"
            className="form-control"
            type="text"
            label="Country"
            name="country"
            required
            value={country}
            onChange={onChange}
          />
        </div>
        <div className="form-group">
          <input
            style={{ marginRight: 10 }}
            type="radio"
            name="gender"
            value="male"
            required
            onChange={onChange}
            checked={gender === "male"}
          />
          <label for="male">Male</label>
          <br></br>
          <input
            type="radio"
            name="gender"
            value="female"
            required
            style={{ marginRight: 10 }}
            onChange={onChange}
            checked={gender === "female"}
          />
          <label for="female">Female</label>
          <br></br>
          {/* <TextField variant="outlined"
            style={{ marginRight: 10 }}
            type="radio"
            name="gender"
            value="both"
            onChange={onChange}
            checked={gender === "both"}
          />
          <label for="both">Both</label> */}
        </div>
        <div className="form-group">
          <TextField
            variant="outlined"
            className="form-control"
            type="date"
            label="Date of Birth"
            required
            name="date_of_birth"
            value={`${
              date_of_birth.year +
              "-" +
              (date_of_birth.month + 1 < 10
                ? `0${date_of_birth.month + 1}`
                : date_of_birth.month + 1) +
              "-" +
              date_of_birth.day
            }`}
            onChange={onChangeDOB}
            max={TodayDate()}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className="form-group">
          <TextField
            type="text"
            multiline
            className="form-control"
            label="A short bio of yourself"
            name="about_me"
            variant="outlined"
            required
            value={about_me}
            onChange={onChange}
            rows={6}
            maxRows={6}
          />
          <small className="form-text">Tell us a little about yourself</small>
        </div>
        <input type="submit" className="btn btn-primary my-1" />
        {/* <Link className="btn btn-light my-1" to="/dashboard">
          Go Back
        </Link> */}
      </form>
    </section>
  );
};

ProfileForm.propTypes = {
  createProfile: PropTypes.func.isRequired,
  getCurrentProfile: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
});

export default connect(mapStateToProps, { createProfile, getCurrentProfile })(
  ProfileForm
);
