import React, { useEffect, useState } from "react";
import { Button, Form, Input, Select, notification } from "antd";
import api from "../../utils/api";
import { useNavigate, useParams } from "react-router-dom";

const WebsiteForm = () => {
  const history = useNavigate();
  const [form] = Form.useForm();
  const { id } = useParams();
  const [activeFilters, setactiveFilters] = useState([]);

  const submitForm = (values) => {
    if (id) {
      api
        .put(`/websites/${id}`, values)
        .then((res) => {
          console.log(res);
          notification.success({
            message: "Website Updated",
            description: "Website has been updated successfully",
          });
          history(`/website-list`);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      api
        .post("/websites", values)
        .then((res) => {
          console.log(res);
          notification.success({
            message: "Website Created",
            description: "Website has been created successfully",
          });
          form.resetFields();
        })
        .catch((err) => {
          console.log(err);
          form.resetFields();
        });
    }
  };

  useEffect(() => {
    if (id) {
      api
        .get(`/websites/${id}`)
        .then((res) => {
          setactiveFilters(res.data.activeFilters);
          form.setFieldsValue({
            name: res.data.name,
            description: res.data.description,
            status: res.data.status,
            statusmessage: res.data.statusmessage,
            city: res.data.city,
            allowpublishers: res.data.allowpublishers,
            bannerAds: res.data.bannerAds,
            location: res.data.location,
            tag: res.data.tag,
            activeFilters: res.data.activeFilters,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);
  return (
    <div>
      <h1 className="large text-primary">{id ? "Update" : "Create"} Website</h1>
      <section>
        <Form
          layout="vertical"
          size="medium"
          onFinish={(values) => {
            submitForm(values);
          }}
          form={form}
        >
          <Form.Item
            label="Name"
            name={"name"}
            rules={[
              {
                required: true,
                message: "Please input the website name!",
              },
            ]}
          >
            <Input
              placeholder="Website Name"
              size="large"
              defaultValue={form.getFieldValue("name")}
            />
          </Form.Item>
          <Form.Item
            label="Description"
            name={"description"}
            rules={[
              {
                required: true,
                message: "Please input the website description!",
              },
            ]}
          >
            <Input.TextArea
              placeholder="Website Description"
              size="large"
              defaultValue={form.getFieldValue("description")}
            />
          </Form.Item>
          <Form.Item
            label="Status"
            name={"status"}
            rules={[
              {
                required: true,
                message: "Please select the website status!",
              },
            ]}
          >
            <Select
              placeholder="Select Status"
              size="large"
              name="status"
              defaultValue={form.getFieldValue("status")}
            >
              <Select.Option value="new">New</Select.Option>
              <Select.Option value="pre-launch">Pre-launch</Select.Option>
              <Select.Option value="active">Active</Select.Option>
              <Select.Option value="paused">Paused</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="Status Message" name="statusmessage">
            <Input
              placeholder="Status Message"
              size="large"
              defaultValue={form.getFieldValue("statusmessage")}
            />
          </Form.Item>
          <Form.Item
            label="City"
            name={"city"}
            rules={[
              {
                required: true,
                message: "Please input the city!",
              },
            ]}
          >
            <Input
              placeholder="City"
              size="large"
              defaultValue={form.getFieldValue("city")}
            />
          </Form.Item>
          <Form.Item
            label="Flag: Allow Other Publishers"
            name={"allowpublishers"}
            rules={[
              {
                required: true,
                message: "Please select the flag!",
              },
            ]}
          >
            <Select
              placeholder="Select Flag"
              size="large"
              defaultValue={form.getFieldValue("allowpublishers")}
            >
              <Select.Option value={true}>Yes</Select.Option>
              <Select.Option value={false}>No</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="Banner Ads" name={"bannerAds"}>
            <Select
              placeholder="Select Ads Status"
              size="large"
              defaultValue={form.getFieldValue("bannerAds")}
            >
              <Select.Option value={true}>Active</Select.Option>
              <Select.Option value={false}>Inactive</Select.Option>
            </Select>
          </Form.Item>
          <section className="activefilter">
            <Form.Item label="Active Filters" name={"activeFilters"}>
              <Select
                mode="multiple"
                placeholder="Add filters"
                size="large"
                defaultValue={form.getFieldValue("activeFilters")}
                onChange={(value) => setactiveFilters(value)}
              >
                <Select.Option value="location">Location</Select.Option>
                <Select.Option value="tags">Tags</Select.Option>
              </Select>
            </Form.Item>
            {activeFilters.includes("location") && (
              <Form.Item label="Location" name={"location"}>
                <Select
                  mode="tags"
                  placeholder="Add Filter Location"
                  size="large"
                  defaultValue={form.getFieldValue("location")}
                ></Select>
              </Form.Item>
            )}
            {activeFilters.includes("tags") && (
              <Form.Item label="Tags" name={"tag"}>
                <Select
                  mode="tags"
                  placeholder="Add Filter Location"
                  size="large"
                  defaultValue={form.getFieldValue("tag")}
                ></Select>
              </Form.Item>
            )}
          </section>

          <Form.Item>
            <div className="d-flex justify-content-between">
              <Button
                type="primary"
                className="btn btn-danger"
                onClick={() => history("/website-list")}
                size="large"
                style={{ marginRight: "10px" }}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                className="btn-primary"
                size="large"
                style={{ marginRight: "10px" }}
              >
                {id ? "Update" : "Create"}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </section>
    </div>
  );
};

export default WebsiteForm;
