import React, { useEffect, useState } from "react";

import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import api from "../../../utils/api";
// import ReservationSuccess from "../ReservationSuccess";
import { useNavigate } from "react-router-dom";
import StripeInput from "./StripeInput";
import { TextField } from "@mui/material";

const CheckoutForm = ({ reservation, event, triggerOnTimeout }) => {
  const history = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const [nameOnCard, setNameOnCard] = useState("");
  const [paymentRes, setPaymentRes] = useState();
  const [LogShow, setLogShow] = useState(false);
  const [logs, setLogs] = useState([]);
  const [timer, setTimer] = useState({
    min: 10,
    sec: "00",
  });

  useEffect(() => {
    const reservedAt = new Date();
    reservedAt.setMinutes(reservedAt.getMinutes() + 10);
    setInterval(() => {
      const timeLeft = reservedAt.getTime() - Date.now();
      const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
      if (minutes === 0 && seconds === 0) {
        triggerOnTimeout(0);
        return;
      }
      setTimer({
        min: minutes,
        sec: seconds < 10 ? "0" + seconds : seconds,
      });
    }, 1000);
  }, []);

  const addLog = (message) => {
    setLogs((prev) => [...prev, message]);
  };

  const handlePaymentFormSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) return;
    setLogs([]);
    addLog("Creating payment intent");
    setLogShow(true);
    let clientSecret;
    try {
      const { data } = await api.post("/reserve/fee-payment", {
        reservationId: reservation._id,
      });
      clientSecret = data.clientSecret;
    } catch (err) {
      if (err.response && err.response.data)
        addLog("ERROR: " + err.response.data.message);
      else addLog("ERROR: Failed to  create payment intent");
      return;
    }
    addLog("Payment intent created");
    const { error, paymentIntent } = await stripe.confirmCardPayment(
      clientSecret,
      {
        payment_method: {
          card: elements.getElement(CardNumberElement),
          billing_details: {
            name: nameOnCard,
            email: reservation.reservedBy.email,
          },
        },
      }
    );
    if (error) {
      addLog("ERROR: " + error.message);
      return;
    }
    addLog(`PaymentIntent (${paymentIntent.id}): ${paymentIntent.status}`);
    addLog(`saving payment status`);

    try {
      paymentIntent.metadata = { reservation_id: reservation._id };
      const { data: savedResult } = await api.post(
        "/reserve/save-fee-payment",
        paymentIntent
      );
      setPaymentRes(savedResult);
      addLog(`payment status saved`);
      setLogShow(false);
    } catch (err) {
      if (err.response && err.response.data)
        addLog("ERROR: " + err.response.data.message);
      else addLog("ERROR: Failed to  create payment intent");
      return;
    }
  };

  return (
    <>
      {!paymentRes && (
        <div className="d-flex flex-column gap-3">
          <div className="row gap-3">
            <p style={{ fontWeight: 600 }}>
              Kindly proceed with your payment else your Reservation slots will
              expire in: {timer.min}:{timer.sec}
            </p>
            <div className="row" style={{ marginLeft: 2 }}>
              Total Payable at Event: $
              {event?.Reservation.price * reservation.quantity} ($
              {event?.Reservation.price}/person)
            </div>
            <div className="row" style={{ marginLeft: 2 }}>
              Payable Now: ${reservation.quantity * 2} (fee)
            </div>
          </div>
          <form
            className="row align-items-center gap-3"
            onSubmit={handlePaymentFormSubmit}
          >
            <div className="col-12 col-md-4 d-flex flex-column gap-3">
              {/* <CardNumberElement className="form-control" /> */}
              <TextField
                label="Credit Card Number"
                name="ccnumber"
                variant="outlined"
                className="form-control"
                required
                fullWidth
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputComponent: StripeInput,
                  inputProps: {
                    component: CardNumberElement,
                  },
                }}
              />
              <TextField
                type="text"
                variant="outlined"
                className="form-control"
                label="Name on Card"
                InputLabelProps={{ shrink: true }}
                value={nameOnCard}
                onChange={(event) => setNameOnCard(event.target.value)}
                required
              />
            </div>
            <div className="col-12 col-md-5 d-flex gap-3">
              <TextField
                label="Expiration Date"
                name="ccexp"
                variant="outlined"
                className="form-control"
                required
                fullWidth
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputComponent: StripeInput,
                  inputProps: {
                    component: CardExpiryElement,
                  },
                }}
              />
              <TextField
                label="CVC"
                name="cvc"
                variant="outlined"
                className="form-control"
                required
                fullWidth
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputComponent: StripeInput,
                  inputProps: {
                    component: CardCvcElement,
                  },
                }}
              />
              {/* <CardExpiryElement className="form-control" />
            <CardCvcElement className="form-control" /> */}
            </div>
            <div className="col-12 col-md-2">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={!stripe || !elements}
              >
                Reserve
              </button>
            </div>
          </form>
        </div>
      )}

      {LogShow && (
        <div className="bg-dark border p-2 mt-2">
          <h5>Please wait.... your payment is being proccessed!</h5>
          {logs.map((log, idx) => (
            <p key={log + idx} className="text-success m-0">
              {log}
            </p>
          ))}
        </div>
      )}
      {paymentRes && (
        <div className="bg-dark border p-2 mt-2">
          <h5>
            <span>
              Confirmation Ticket No.{" "}
              {paymentRes?.reservation?.ticket?.H +
                paymentRes?.reservation?.ticket?.N}{" "}
              has been sent to your email. You can also access it from the
              Dashboard {`>`} My Reservations
            </span>
          </h5>
        </div>
      )}
    </>
  );
};

export default CheckoutForm;
