import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import api from "../../utils/api";
import { TextField, Autocomplete } from "@mui/material";
const StaffAccess = () => {
  const { id } = useParams();
  const ref = useRef();
  const [Data, setData] = useState([]);
  // const [Event, setEvent] = useState(null);
  const [StaffList, setStaffList] = useState([]);
  const { user } = useSelector((state) => state.auth);
  const [current, setcurrent] = useState();
  const add = () => {
    if (current === Data[0]?.email && current !== user.email) {
      if (
        StaffList.length === 0 ||
        StaffList?.every((email) => email !== current)
      ) {
        setStaffList([...StaffList, current]);
        api.patch(`/events/${id}`, {
          Access: [...StaffList, current],
        });
        setData([]);
        setcurrent("");
      }
    }
  };
  const SearchStaff = (email) => {
    setcurrent(email.toLowerCase());
    if (email.includes("@")) {
      api
        .post(`/auth/get-user`, { email })
        .then(({ data }) => {
          if (data.status) {
            if (user.email !== data?.user?.email) setData([data.user]);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };
  useEffect(() => {
    api
      .get(`/events/${id}`)
      .then(({ data }) => {
        setStaffList(data?.Access);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [id]);
  const RemoveStaff = (email) => {
    api
      .patch(`/events/${id}`, {
        Access: StaffList.filter((emails) => emails !== email),
      })
      .then(({ data }) => {
        setStaffList(StaffList.filter((emails) => emails !== email));
      })
      .catch((err) => {
        console.error(err);
      });
  };
  return (
    <section>
      <div>
        <div className="booking-info-title">Staff Information</div>
        <div className="borderCont">
          <div className="StaffContainer">
            <Autocomplete
              fullWidth
              freeSolo
              options={Data.map((option) => option.email)}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  ref={ref}
                  {...params}
                  label="Search User by Email"
                  variant="outlined"
                  onChange={(e) => SearchStaff(e.target.value)}
                />
              )}
            />
            {current?.length > 0 && (
              <button
                className={
                  Data.length > 0 && current !== user.email
                    ? "addStaffBtn"
                    : "addStaffBtnDisabled"
                }
                onClick={() => add()}
              >
                {Data.length > 0 ? "Add to staff list" : "User cannot be added as staff"}
              </button>
            )}
          </div>
          {StaffList?.length > 0 && (
            <div className="stafHeading">List of staff members</div>
          )}
          <div className="staffmember">
            {StaffList.map((email) => {
              return (
                <li>
                  {email}
                  <svg
                    onClick={() => RemoveStaff(email)}
                    style={{
                      cursor: "pointer",
                      marginLeft: "10px",
                      color: "red",
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-x-circle-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                  </svg>
                </li>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default StaffAccess;
