import React, { useState } from "react";
import { resetEmail } from "../../actions/auth";
import { useDispatch } from "react-redux";
import { setAlert } from "../../actions/alert";
import TextField from "@mui/material/TextField";

const ResetPassword = () => {
  const [email, setemail] = useState("");
  const dispatch = useDispatch();
  const onSubmit = () => {
    dispatch(resetEmail({ email }));
    dispatch(
      setAlert(
        "If this email is registered, an email will be sent there",
        "success"
      )
    );
    setemail("");
  };
  return (
    <section>
      <div>
        <h1 className="large text-primary">Reset Password</h1>
        <div className="d-flex flex-wrap" style={{rowGap: '10px',}}>
          <div className="form-group" style={{ width: 300, marginRight: 10 }}>
            <TextField
              type="email"
              required
              label="Your Email Address"
              name="email"
              className="form-control"
              value={email}
              onChange={(e) => setemail(e.target.value)}
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <input
              type="submit"
              className="btn btn-primary"
              value="Reset Now"
              onClick={() => onSubmit()}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ResetPassword;
