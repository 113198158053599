import React from "react";
import PropTypes from "prop-types";

const ProfileTop = ({ profile: { display_name, about_me } }) => {
  return (
    <div className="profile-top p-2">
      <h1 className="large text-primary">{display_name}</h1>
      <div
        style={{
          fontSize: "14px",
          textAlign: "left",
          border: "1px solid #dee2e6",
          padding: "10px",
          borderRadius: "5px",
        }}
      >
        {about_me}
      </div>
    </div>
  );
};

ProfileTop.propTypes = {
  profile: PropTypes.object.isRequired,
};

export default ProfileTop;
