import React, { useEffect, useState } from "react";
import api from "../../utils/api";
import Spinner from "../layout/Spinner";
import { useSelector } from "react-redux";
import moment from "moment";
import "./style.css";
import { QRCode, Space } from "antd";

const Subscriptions = () => {
  const { user } = useSelector((state) => state.auth);
  const [subscriptions, setsubscriptions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api
      .get(`/reserve/subscribe/my-subscriptions/${user?.cmtyUserId}`)
      .then((res) => {
        debugger;
        setsubscriptions(res.data.subscriptions);
        setTimeout(() => {
          setLoading(false);
        }, 300);
      })
      .catch((err) => {
        setTimeout(() => {
          setLoading(false);
        }, 300);
      });
  }, [user]);
  return (
    <div>
      <h1 className="text-primary">Subscriptions</h1>
      {loading && <Spinner />}
      {!loading && (
        <div>
          {subscriptions?.map((sub) => {
            return (
              <div className="subsCont">
                <section className="mb-2">
                  <div>
                    <span className="subskeys">For : </span>{" "}
                    {sub?.subscribedService}
                  </div>
                  <div>
                    <span className="subskeys">Status : </span>{" "}
                    {sub?.subscriptionStatus}
                  </div>
                  <div>
                    <span className="subskeys">Next Payment : </span>
                    {moment(sub?.subscriptionNextPaymentDate).format(
                      "DD/MM/YYYY"
                    )}
                  </div>
                  <div>
                    <span className="subskeys">Price : </span>{" "}
                    {sub?.subscriptionPaymentAmount / 100}{" "}
                    {sub?.subscriptionPaymentCurrency}
                  </div>
                </section>
                <section>
                  <div
                    style={{
                      filter:
                        sub?.subscriptionStatus === "active" ? "" : "blur(5px)",
                    }}
                  >
                    <QRCode type="svg" value={sub?.cmtyUserId} />
                  </div>
                </section>
              </div>
            );
          })}
        </div>
      )}
      {!loading && subscriptions?.length === 0 && (
        <div
          style={{
            textAlign: "center",
            fontSize: "20px",
            marginTop: "20px",
          }}
        >
          No subscriptions found
        </div>
      )}
    </div>
  );
};

export default Subscriptions;
