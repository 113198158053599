import React, { useEffect } from "react";
import Navbar from "./components/layout/Navbar";
import RouteConfig from "./components/routing/Routes";
import { LOGOUT } from "./actions/types";
import history from "./utils/history";
import { CustomRouter } from "./utils/CustomRouter";
import { Provider } from "react-redux";
import store from "./store";
import { loadUser } from "./actions/auth";
import setAuthToken from "./utils/setAuthToken";
import "./App.css";
import Alert from "./components/layout/Alert";

const App = () => {
  useEffect(() => {
    // check for token in LS
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    store.dispatch(loadUser());

    // log user out from all tabs if they log out in one tab
    window.addEventListener("storage", () => {
      if (!localStorage.token) store.dispatch({ type: LOGOUT });
    });
  }, []);
  return (
    <Provider store={store}>
      <CustomRouter history={history}>
        <Navbar />
        <Alert />
        <div className="container">
          <RouteConfig />
        </div>
      </CustomRouter>
    </Provider>
  );
};

export default App;
