import React, { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import BasicDetailsForm from "./EventForm/BasicDetailsForm";
import api from "../../utils/api";
import moment from "moment";
import { useSelector } from "react-redux";

export default function UpdateEvent() {
  const history = useNavigate();
  const params = useParams();

  const { user } = useSelector((state) => state.auth);

  const [submitting, setSubmmiting] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [eventData, setEventData] = useState({
    loading: true,
    errorMsg: null,
    event: null,
  });

  const event = eventData.event;

  const defaultValues = {
    eventName: event?.eventName ?? "",
    description: event?.description ?? "",
    location: event?.location ?? "",
    eventStartDateAndTime: event?.eventStartDateAndTime
      ? event?.eventStartDateAndTime
      : new Date(),
    mediaList: event?.mediaList ?? [],
    eventDuration: event?.eventDuration ?? "",
    publishedOn: event?.publishedOn ?? [],
    Tags: event?.Tags ?? [],
    checkIn: event?.checkIn ?? "enabled",
    codeCheckIn: event?.codeCheckIn ?? "JuznowSelfCheckIn",
    directions: event?.directions ?? "",
    locateUs: event?.locateUs ?? "",
    City: event?.City ?? "",
    OrganizerAlias: event?.OrganizerAlias ?? "",
    isRecurring: event?.isRecurring ?? false,
    Recurrence: event?.Recurrence ?? "Weekly",
    Dayofweek: event?.Dayofweek ?? [],
    startRecurrence: event?.startRecurrence
      ? event?.startRecurrence
      : new Date(),
    endRecurrence: event?.endRecurrence ? event?.endRecurrence : new Date(),
    eventTime: event?.eventTime ? event?.eventTime : new Date(),
    TagsValues: event?.Tags ?? ["speeddating", "social"],
    // eventType: event?.eventType ?? "",
  };

  useEffect(() => {
    setEventData((prev) => ({ ...prev, loading: true, errorMsg: null }));

    api(`/events/${params.id}`)
      .then(({ data }) => {
        setEventData((prev) => ({ ...prev, event: data, loading: false }));
      })
      .catch((err) => {
        setEventData((prev) => ({
          ...prev,
          event: null,
          loading: false,
          errorMsg: "Event not found",
        }));
      });
  }, [params.id]);

  const handleFormSubmit = async (data) => {
    if (submitting) return;
    setSubmmiting(true);
    setErrorMsg(null);
    try {
      await api.patch(`/events/${params.id}`, data);
      setSubmmiting(false);
      history(`/events/${params.id}/add-reservation`);
    } catch (err) {
      setSubmmiting(false);
      setErrorMsg("Failed to update event");
    }
  };

  if (eventData.loading) return <h1>Loading...</h1>;
  if (eventData.errorMsg) return <h1>{eventData.errorMsg}</h1>;
  if (eventData?.event?.createdBy?._id !== user?._id && !user?.is_siteadmin)
    return <Navigate to={"/events"} />;
  return (
    <div>
      {submitting ? (
        <div className="alert alert-info">
          Please wait while event is being updated
        </div>
      ) : null}
      {errorMsg ? <div className="alert alert-danger">{errorMsg}</div> : null}
      <section>
        <h1 className="large text-primary">Edit Event </h1>
      </section>
      <BasicDetailsForm
        onSubmit={handleFormSubmit}
        defaultValues={defaultValues}
        UpdateEvent={true}
      />
    </div>
  );
}
