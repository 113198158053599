import React, { useCallback, useEffect, useState } from "react";
import api from "../../utils/api";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";

const limit = 10;
export default function EventDashboard() {
  const [events, setEvents] = useState([]);
  const [pagination, setPagination] = useState({});

  const getEvents = useCallback(async (page = 1) => {
    const { data } = await api.get("/events", {
      params: {
        limit,
        page,
      },
    });
    setPagination((prev) => ({ ...prev, ...data.pagination }));
    setEvents((prev) => prev.concat(data.events));
  }, []);

  useEffect(() => {
    getEvents();
  }, [getEvents]);

  const fetchData = () => {
    getEvents(pagination.currentPage + 1);
  };

  return (
    <div>
      <div className="overflow-scroll">
        <InfiniteScroll
          dataLength={pagination.currentPage * limit}
          next={fetchData}
          hasMore={pagination.hasNext}
          loader={<h4>Loading...</h4>}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>Yay! You have seen it all</b>
            </p>
          }
        >
          <table className="table table-bordered table-responsive">
            <thead>
              <tr>
                <th>Organiser</th>
                <th>Event</th>
                <th>Date</th>
                <th>Time</th>
                <th>Action</th>
              </tr>
            </thead>

            <tbody>
              {events?.map((event) => (
                <tr key={event._id}>
                  <td>{event?.createdBy?.display_name}</td>
                  <td>
                    <a
                      href={`${window.location.protocol}//${window.location.host}/events/${event._id}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {`${window.location.protocol}//${window.location.host}/events/${event._id}`}
                    </a>
                  </td>
                  <td style={{ whiteSpace: "nowrap" }}>
                    {moment(event.eventStartDateAndTime).format("DD MMM YYYY")}
                  </td>
                  <td style={{ whiteSpace: "nowrap" }}>
                    {moment(event.eventStartDateAndTime).format("HH:MM")}
                  </td>
                  <td>
                    <div className="d-flex">
                      <button className="btn btn-link">Copy</button>
                      <button className="btn btn-link">Cancel</button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </InfiniteScroll>
      </div>
    </div>
  );
}
