import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addPost } from "../../actions/post";

const PostForm = ({ addPost, initialText, initialLink, handleFormSubmit }) => {
  const [text, setText] = useState(initialText ?? "");
  const [link, setLink] = useState(initialLink ?? "");

  const handleSubmit = (e) => {
    e.preventDefault();
    // alert(link);
    if (initialText || initialLink) {
      handleFormSubmit({ text, link });
      return;
    }
    addPost({ text, link });
    setText("");
    setLink("");
  };

  return (
    <div className="post-form">
      {/* <div className="bg-primary">
        <h3>
          {initialText || initialLink ? "Update Post" : "Say Something..."}
        </h3>
      </div> */}
      <form className="form my-1" onSubmit={handleSubmit}>
        <textarea
          name="text"
          cols="30"
          rows="5"
          placeholder="Create a post"
          value={text}
          onChange={(e) => setText(e.target.value)}
          required
        />
        <input
          type="text"
          name="link"
          placeholder="Add a link"
          value={link}
          onChange={(e) => setLink(e.target.value)}
        />
        <input type="submit" className="btn btn-dark my-1" value="Submit" />
      </form>
    </div>
  );
};

PostForm.propTypes = {
  addPost: PropTypes.func.isRequired,
  handleFormSubmit: PropTypes.func,
  initialText: PropTypes.string,
  initialLink: PropTypes.string,
};

export default connect(null, { addPost })(PostForm);
