import React, { useCallback, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import api from "../../utils/api";
import moment from "moment";

const limit = 10;
export default function Payments() {
  const [loading, setLoading] = useState(true);
  const [payments, setPayments] = useState([]);
  const [pagination, setPagination] = useState({});

  const [status, setStatus] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();

  const getEvents = useCallback(async (page = 1, from, to, status) => {
    try {
      const { data } = await api.get("/reserve/payments", {
        params: {
          limit,
          page,
          status,
          from,
          to,
        },
      });
      setPagination((prev) => ({ ...prev, ...data.pagination }));
      setPayments((prev) => prev.concat(data.payments));
      setLoading(false);
    } catch (error) {}
  }, []);

  useEffect(() => {
    setLoading(true);
    getEvents();
  }, [getEvents]);

  const fetchData = () => {
    getEvents(pagination.currentPage + 1, from, to, status);
  };

  const handleFilterClick = (e) => {
    e.preventDefault();
    setLoading(true);
    setPayments([]);
    setPagination({});
    getEvents(1, from, to, status);
  };

  return (
    <div className=" py-3 d-flex flex-column gap-5">
      <form className="row" onSubmit={handleFilterClick}>
        <div className="form-group col-xs-12 col-sm-6 col-md-4 col-lg-2">
          <label htmlFor="status">Status</label>
          <select
            id="status"
            className="form-select"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          >
            <option value="">All</option>
            <option value="succeeded">Valid</option>
            <option value="refunded">Refunded</option>
            <option value="declined">Declined</option>
          </select>
        </div>

        <div className="form-group col-xs-12 col-sm-6 col-md-4 col-lg-2">
          <label htmlFor="fromdate">From</label>
          <input
            id="fromdate"
            type="date"
            className="form-control"
            onChange={(e) => setFrom(e.target.value)}
          />
        </div>
        <div className="form-group col-xs-12 col-sm-6 col-md-4 col-lg-2">
          <label htmlFor="todate">To</label>
          <input
            id="todate"
            type="date"
            className="form-control"
            onChange={(e) => setTo(e.target.value)}
          />
        </div>
        {/* <div className="form-group col-xs-12 col-sm-6 col-md-4 col-lg-2">
          <label htmlFor=""></label>
          <input type="text" placeholder="city" className="form-control" />
        </div>
        <div className="form-group col-xs-12 col-sm-6 col-md-4 col-lg-2">
          <label htmlFor=""></label>
          <input type="number" placeholder="pincode" className="form-control" />
        </div> */}

        <div className="from-group col-xs-12 col-sm-6 col-md-4 col-lg-2">
          <label></label>
          <button type="submit" className="btn btn-primary form-control">
            Filter
          </button>
        </div>
      </form>

      <div className="overflow-scroll">
        {!loading && payments?.length && pagination ? (
          <InfiniteScroll
            dataLength={pagination.currentPage * limit}
            next={fetchData}
            hasMore={pagination.hasNext}
            loader={<h4>Loading...</h4>}
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
          >
            <table className="table table-bordered table-responsive">
              <thead>
                <tr>
                  <th>Payment Id</th>
                  <th>Username</th>
                  <th>Event</th>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Fee</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>

              <tbody>
                {payments?.map((payment) => (
                  <tr key={payment?._id}>
                    <td>{payment?._id}</td>
                    <td>{payment?.paidBy?.name}</td>
                    <td>
                      <a
                        href={`${window.location.protocol}//${window.location.host}/events/${payment?.event?._id}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {`${window.location.protocol}//${window.location.host}/events/${payment?.event?._id}`}
                      </a>
                    </td>
                    <td style={{ whiteSpace: "nowrap" }}>
                      {moment(payment?.createdAt).format("DD MMM YYYY")}
                    </td>
                    <td style={{ whiteSpace: "nowrap" }}>
                      {moment(payment?.createdAt).format("HH:MM")}
                    </td>
                    <td>${2}</td>
                    <td>${payment?.event?.Reservation?.price}</td>
                    <td>
                      {payment?.paymentDetails?.status === "succeeded"
                        ? "Valid"
                        : payment?.paymentDetails?.status}
                    </td>
                    <td>
                      <button className="btn">Refund</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </InfiniteScroll>
        ) : loading ? (
          <h1>Loading...</h1>
        ) : (
          "Not found"
        )}
      </div>
    </div>
  );
}
