import React, { memo, useMemo } from "react";
import Countdown from "react-countdown";

const Counter = ({ TimeRemian }) => {
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      return <span>Closed</span>;
    } else {
      return (
        <div className="d-flex timerContainer">
          <div className="timerMr">
            <div>HH</div>
            <div className="d-flex justify-content-center align-items-center fS20">
              {hours}
            </div>
          </div>
          :
          <div className="timerMr">
            <div>MM</div>
            <div className="d-flex justify-content-center align-items-top fS20">
              {minutes}
            </div>
          </div>
          :
          <div className="timerMr">
            <div>SS</div>
            <div className="d-flex justify-content-center align-items-center fS20">
              {seconds}
            </div>
          </div>
        </div>
      );
    }
  };
  return (
    <div key={TimeRemian}>
      <Countdown date={Date.now() + parseInt(TimeRemian)} renderer={renderer} />
    </div>
  );
};

export default memo(Counter);
