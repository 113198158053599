import React from "react";
import Portal from "../Portal";

import "./dialog.scss";

export default function Dialog({ children, open, onClose }) {
  // useEffect(() => {
  //   window.addEventListener("keyup", (e) => {
  //     if (e.key === "Escape") onClose();
  //   });

  //   return () => {
  //     window.removeEventListener("keyup", () => {});
  //   };
  // }, [onClose]);

  if (!open) return null;
  return (
    <Portal>
      <div className="dialog">
        <div className="dialog-overlay" onClick={onClose}>
          <div className="dialog-body" onClick={(e) => e.stopPropagation()}>
            {children}
          </div>
        </div>
      </div>
    </Portal>
  );
}
