import React, { Fragment, useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import api from "../../utils/api";
import moment from "moment";
import "./ManageEvent.css";
import { useSelector, useDispatch } from "react-redux";
import EventStats from "./EventStats";
import StaffAccess from "./staffAccess";
import Spinner from "../layout/Spinner";
import { Button } from "@mui/material";
import { setAlert } from "../../actions/alert";
import Switch from "@mui/material/Switch";
import { Modal, notification } from "antd";

const ManageEvent = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [event, setEvent] = useState();
  const [ViewCode, setViewCode] = useState(false);
  const [stats, setStats] = useState(null);
  const [StaffAdd, setStaffAdd] = useState(false);
  const { showStat } = useSelector((state) => state.event);
  const { user } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(true);
  const [deleteAll, setdeleteAll] = useState(false);
  useEffect(() => {
    api
      .get(`/events/${id}`)
      .then(({ data }) => {
        setEvent(data);
      })
      .catch((err) => {
        console.error(err);
      });
    api
      .get(`/events/${id}/stats`)
      .then(({ data }) => {
        setStats(data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [id]);
  const handleDeleteClick = async () => {
    try {
      await api.delete(`/events/${id}`);
      history(`/events`);
      notification.success({
        message: "Event deleted successfully",
      });
    } catch (err) {}
  };
  const ReservationList = () => {
    api
      .get(`/reserve/reservation-list/${id}`, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        responseType: "arraybuffer",
      })
      .then((res) => {
        const temp = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = temp;
        link.setAttribute("download", "ReservationList.pdf");
        document.body.appendChild(link);
        link.click();
      });
  };
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 300);
  }, [user]);
  const SendInvite = () => {
    let obj = {
      eventId: event._id,
      pollId: event?.pollId,
      pollOpId: event.pollOpId,
    };
    api
      .post("/events/invite", obj)
      .then(({ data }) => {
        dispatch(setAlert(data.status, "success"));
        api
          .get(`/events/${id}`)
          .then(({ data }) => {
            setEvent(data);
          })
          .catch((err) => {
            console.error(err);
          });
      })
      .catch(({ response }) => {
        dispatch(setAlert(response?.data?.message, "error"));
      });
  };
  const SendInviteAll = () => {
    let obj = {
      eventId: event._id,
      pollId: event?.pollId,
      pollOpId: event.pollOpId,
    };
    api
      .post("/events/invite/all", obj)
      .then(({ data }) => {
        dispatch(setAlert(data.status, "success"));
        api
          .get(`/events/${id}`)
          .then(({ data }) => {
            setEvent(data);
          })
          .catch((err) => {
            console.error(err);
          });
      })
      .catch(({ response }) => {
        dispatch(setAlert(response?.data?.message, "error"));
      });
  };

  const handlepublishedOn = (e) => {
    if (e.target.checked) {
      api
        .patch(`/events/${id}`, { publishedOn: ["clublink"] })
        .then(({ data }) => {
          dispatch(setAlert("Event updated", "success"));
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      api
        .patch(`/events/${id}`, { publishedOn: [] })
        .then(({ data }) => {
          dispatch(setAlert("Event updated", "success"));
        })
        .catch((err) => {
          console.error(err);
        });
    }
    api
      .get(`/events/${id}`)
      .then(({ data }) => {
        setEvent(data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const deleteAllRecurrence = async () => {
    try {
      await api.delete(`/events/recurrence/${event?.parentEvent}`);
      notification.success({
        message: "Events deleted successfully",
      });
      history(`/events`);
    } catch (err) {}
  };

  return (
    <Fragment>
      {loading && <Spinner />}
      {!loading && (
        <section>
          <div className="page-title-back-btn-outer">
            <div
              className="back-btn pointer-cursor"
              onClick={() => history(-1)}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                <path d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z" />
              </svg>
              Back
            </div>
            {(user?._id === event?.createdBy?._id || user?.is_siteadmin) && (
              <div className="page-title" style={{ fontSize: 32 }}>
                Manage Event
              </div>
            )}
          </div>
          {user?._id === event?.createdBy?._id || user?.is_siteadmin ? (
            <Fragment>
              <div className="event-manage-outer">
                <div className="event-title">{event?.eventName}</div>
                <div className="date-time-outer">
                  <span className="date">
                    {moment(event?.eventStartDateAndTime).format("DD/MM/YYYY")}
                  </span>
                  <span className="time">
                    {" "}
                    {moment(event?.eventStartDateAndTime).format("LT")} -{" "}
                    {moment(event?.eventStartDateAndTime)
                      .add(event?.eventDuration, "hours")
                      .format("LT")}{" "}
                    ({event?.eventDuration}hrs)
                  </span>
                </div>
                <div className="event-detail-row-outer">
                  <div className="event-detail-title">Event Details</div>
                  <div className="event-details-btn-outer">
                    <div className="event-details-btn-left">
                      <Link to={`/events/${id}/update`}>Edit</Link>
                      <Link
                        to={``}
                        onClick={() => handleDeleteClick()}
                        className="ed-delete-btn"
                      >
                        Delete
                      </Link>{" "}
                      {event.isRecurring && (
                        <Link
                          to={``}
                          // onClick={() => deleteAllRecurrence()}
                          onClick={() => setdeleteAll(true)}
                          className="ed-delete-btn"
                        >
                          Delete All Recurrence
                        </Link>
                      )}
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {ViewCode && (
                        <div>
                          Code :{" "}
                          {event?.Reservation?.code
                            ? event?.Reservation?.code
                            : "NO CODE REQUIRED"}
                        </div>
                      )}
                    </div>
                    <div className="event-details-btn-right">
                      <Link
                        to={``}
                        className="ed-view-code-btn"
                        onClick={() => setViewCode(true)}
                      >
                        View Code
                      </Link>
                      <Link to={``} className="ed-share-btn">
                        Share
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="event-operation-row-outer">
                  <div className="event-operation-title">Event Operation</div>
                  <div className="event-operation-btn-outer">
                    <Link to={`/events/${id}/scanner`}>Scanner</Link>
                    <Link to={``} onClick={() => setStaffAdd(!StaffAdd)}>
                      {StaffAdd ? "Hide Staff" : "Show Staff"}
                    </Link>
                    <Link
                      to={``}
                      onClick={(e) => dispatch({ type: "TOGGLE_STATS" })}
                    >
                      {showStat ? "Hide Statistics" : "Show Statistics"}
                    </Link>
                    <Link to={``} onClick={() => ReservationList()}>
                      Reservation List
                    </Link>
                    {event.pollId && (
                      <Button
                        onClick={() => SendInvite()}
                        className={
                          event.invite && event.pollId
                            ? "send-invite"
                            : "send-invite-false"
                        }
                      >
                        {event.invite && event.pollId
                          ? `Invite people who voted for option`
                          : `Email invite sent to selected`}
                      </Button>
                    )}
                    {event?.pollId && (
                      <Button
                        onClick={() => SendInviteAll()}
                        className={
                          event?.inviteAll && event?.pollId
                            ? "send-invite"
                            : "send-invite-false"
                        }
                      >
                        {event?.inviteAll && event?.pollId
                          ? `Invite all poll participants`
                          : `Email invite sent to all`}
                      </Button>
                    )}
                  </div>
                </div>
                <div className="event-operation-row-outer mt-4">
                  <div className="event-operation-title">Share With</div>
                  <div className="event-operation-btn-outer">
                    <div>
                      Clublink
                      <Switch
                        onChange={handlepublishedOn}
                        checked={
                          Array.isArray(event?.publishedOn)
                            ? event?.publishedOn.includes("clublink")
                            : false
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              {showStat && <EventStats stats={stats} />}
              {StaffAdd && <StaffAccess />}
            </Fragment>
          ) : (
            <div className="d-flex">
              {user?._id !== event?.createdBy?._id && !loading && (
                <>
                  <div className="pt-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="white"
                      class="bi bi-x-octagon-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M11.46.146A.5.5 0 0 0 11.107 0H4.893a.5.5 0 0 0-.353.146L.146 4.54A.5.5 0 0 0 0 4.893v6.214a.5.5 0 0 0 .146.353l4.394 4.394a.5.5 0 0 0 .353.146h6.214a.5.5 0 0 0 .353-.146l4.394-4.394a.5.5 0 0 0 .146-.353V4.893a.5.5 0 0 0-.146-.353L11.46.146zm-6.106 4.5L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708z" />
                    </svg>
                  </div>

                  <div className="page-title" style={{ marginLeft: 10 }}>
                    If you are seeing this, you are probably took a wrong turn.
                    Try logging out and logging back in.
                  </div>
                </>
              )}
            </div>
          )}
        </section>
      )}
      <Modal
        title="Do you want to delete all recurrences associated with this event ?"
        open={deleteAll}
        centered
        closable={false}
        footer={null}
      >
        <section>
          <div
            className="event-details-btn-left"
            style={{
              display: "flex",
              justifyContent: "end",
              gap: "10px",
            }}
          >
            <Link
              to={``}
              onClick={() => deleteAllRecurrence()}
              style={{
                backgroundColor: "red",
                color: "white",
                padding: "10px",
                borderRadius: "5px",
                width: "100px",
                textAlign: "center",
              }}
            >
              Yes
            </Link>
            <Link
              style={{
                backgroundColor: "green",
                color: "white",
                padding: "10px",
                borderRadius: "5px",
                width: "100px",
                textAlign: "center",
              }}
              to={``}
              onClick={() => setdeleteAll(false)}
              className="ed-view-code-btn"
            >
              No
            </Link>
          </div>
        </section>
      </Modal>
    </Fragment>
  );
};

export default ManageEvent;
