import React, { useState } from "react";
import {
  Select,
  TextField,
  FormControl,
  Button,
  MenuItem,
  InputLabel,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import api from "../../utils/api";
import { useNavigate } from "react-router-dom";
import "./style.css";
const CreatePoll = () => {
  const history = useNavigate();
  const [question, setQuestion] = useState("");
  const [options, setOptions] = useState([]);
  const [endBy, setendBy] = useState(60);
  const publish = () => {
    api
      .post("/poll", {
        question,
        options,
        endBy,
      })
      .then((res) => {
        history(`/poll/${res?.data?.poll._id}`);
      })
      .catch((err) => {});
  };
  const updateQuestionOptions = (i, v) => {
    setOptions((options) => {
      options[i].option = v;
      return options;
    });
  };
  const Slots = [
    { label: "5min", value: 5 },
    { label: "15min", value: 15 },
    { label: "1hr", value: 60 },
    { label: "4hr", value: 240 },
    { label: "8hr", value: 480 },
  ];
  return (
    <div className="p10">
      <section>
        <h1 className="large text-primary">Create Poll</h1>
      </section>
      <section className="mb15">
        <TextField
          label="Create a poll question"
          name="ccnumber"
          variant="outlined"
          className="form-control"
          fullWidth
          onChange={(e) => setQuestion(e.target.value)}
        />
      </section>
      <section>
        <FormControl fullWidth variant="outlined">
          <InputLabel id="demo-multiple-name-label">
            Select poll duration
          </InputLabel>
          <Select
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"
            value={endBy}
            onChange={(val) => setendBy(val.target.value)}
            input={<OutlinedInput label="Select poll duration" />}
          >
            {Slots.map((d) => (
              <MenuItem key={d.val} value={d.value}>
                {d.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </section>
      <section className="p10">
        <div>
          <div className="PollOptionsCont">
            {options?.map((option, index) => {
              return (
                <div style={{ display: "flex", gap: 10 }}>
                  <TextField
                    key={option.option}
                    label={`Option ${index + 1}`}
                    variant="outlined"
                    className="form-control"
                    fullWidth
                    defaultValue={option.option}
                    onChange={(e) =>
                      updateQuestionOptions(index, e.target.value)
                    }
                  />
                  <Button
                    className="rm"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setOptions(options.filter((_, i) => i !== index));
                    }}
                  >
                    Remove
                  </Button>
                </div>
              );
            })}
          </div>
          <Button
            className="addMOre"
            variant="contained"
            color="primary"
            onClick={() => setOptions([...options, { option: "" }])}
          >
            {options.length > 0 ? "Add more options" : "Add poll option"}
          </Button>
        </div>
      </section>
      {options.length >= 2 && (
        <section className="p10 d-end">
          <Button
            className="pb-pl-btn"
            variant="contained"
            onClick={() => publish()}
          >
            Publish Poll
          </Button>
        </section>
      )}
    </div>
  );
};

export default CreatePoll;
