import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getCurrentProfile, deleteAccount } from "../../actions/profile";
import Button from "@mui/material/Button";
import Spinner from "../layout/Spinner";

const Dashboard = ({
  getCurrentProfile,
  auth: { user },
  profile: { profile },
}) => {
  const navigate = useNavigate();
  const [loading, setloading] = useState(true);
  useEffect(() => {
    getCurrentProfile();
  }, [getCurrentProfile]);

  useEffect(() => {
    profile !== null && setloading(false);
  }, [profile]);

  // JSX Style
  const mYpostSt = {
    fontWeight: "600",
    color: "#18a2b8",
    cursor: "pointer",
    borderColor: "#18a2b8",
  };
  return (
    <Fragment>
      {loading && <Spinner />}
      {!loading && (
        <section>
          <h1 className="large text-primary">Dashboard</h1>
          <p className="lead">
            <i className="fas fa-user" /> Welcome{" "}
            {profile?.first_name ? profile?.first_name : profile?.full_name}
          </p>
          {profile !== null ? (
            <></>
          ) : (
            <>
              <p>You have not yet setup your profile</p>
              <Link to="/create-profile" className="btn btn-primary my-1">
                Create Profile
              </Link>
            </>
          )}
          <section className="jz-dash-cont">
            <div className="mb-2">
              <i className="fas fa-user" />
            </div>
            <div className="d-flex flex-wrap dashBtn">
              <Button
                variant="outlined"
                onClick={() => navigate("/my-reservations")}
                style={mYpostSt}
              >
                Reservations
              </Button>

              {profile !== null && (
                <Button
                  variant="outlined"
                  onClick={() =>
                    navigate(`/u/${profile?.display_name.replaceAll(" ", "")}`)
                  }
                  style={mYpostSt}
                >
                  Profile
                </Button>
              )}

              <Button
                variant="outlined"
                onClick={() => navigate("/account-settings")}
                style={mYpostSt}
              >
                Account
              </Button>
              <Button
                variant="outlined"
                onClick={() => navigate("/subscriptions")}
                style={mYpostSt}
              >
                Subscriptions
              </Button>
            </div>
          </section>
          <section className="jz-dash-cont">
            <div className="mb-2">
              <i className="fas fa-users" />
            </div>
            <div className="d-flex flex-wrap dashBtn">
              {/* {process.env.REACT_APP_POSTSHOW && (
                <Button
                  variant="outlined"
                  onClick={() => navigate("/posts")}
                  style={mYpostSt}
                >
                  Posts
                </Button>
              )} */}

              <Button
                variant="outlined"
                onClick={() => navigate("/events")}
                style={mYpostSt}
              >
                Events
              </Button>

              {/* <Button
                variant="outlined"
                onClick={() => navigate("/poll-list")}
                style={mYpostSt}
              >
                Polls
              </Button> */}

              {/* <Button
                variant="outlined"
                onClick={() => navigate("/my-groups")}
                style={mYpostSt}
              >
                Groups
              </Button> */}
              <Button
                variant="outlined"
                onClick={() => navigate("/website-list")}
                style={mYpostSt}
              >
                Websites
              </Button>
            </div>
          </section>
        </section>
      )}
    </Fragment>
  );
};

Dashboard.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  deleteAccount: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
});

export default connect(mapStateToProps, { getCurrentProfile, deleteAccount })(
  Dashboard
);
