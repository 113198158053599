import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// import { setAlert } from '../../actions/alert';
// import { register } from '../../actions/auth';
import PropTypes from "prop-types";

// const HowManyHours = ({ setAlert, register, isAuthenticated }) => {

const HowManyHours = ({ isAuthenticated }) => {
  const [formData, setFormData] = useState({
    amount: "",
    income: "",
    tax: "",
    commission: "",
    timeRequired: "",
  });

  const { amount, income, tax, commission, timeRequired } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();

    //use parsefloat to get the numbers and calculate
    //Replace with 0 if false value like NaN.
    var amountNum = parseFloat(amount) || 0;
    var incomeHrly = parseFloat(income) || 0;
    var taxPct = parseFloat(tax) || 0;
    var commissionPct = parseFloat(commission) || 0;

    /* 
    if using expenses as a threshold then need to pass this first
    if using expenses as a % then need total number of hours worked.
    If this is referring to additional hours, then just need

  */

    var savingHrly = incomeHrly * (1 - (taxPct + commissionPct) / 100);
    var timeReq = Math.ceil(amountNum / savingHrly) || 0;
    setFormData({ ...formData, timeRequired: timeReq });
  };

  if (isAuthenticated) {
    // return <Navigate to="/dashboard" />;
  }

  return (
    <Fragment>
      <h1 className="large text-primary">How many hours?</h1>
      <p className="lead">
        <i className="fas fa-user" /> Calculate how many hours to work make the
        required amount!
      </p>
      <form className="form" onSubmit={onSubmit}>
        <div className="form-group">
          <input
            type="text"
            placeholder="Enter Amount eg 99"
            name="amount"
            value={amount}
            onChange={onChange}
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            placeholder="Hourly Wage"
            name="income"
            value={income}
            onChange={onChange}
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            placeholder="Tax (%)"
            name="tax"
            value={tax}
            onChange={onChange}
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            placeholder="Commission paid (%)"
            name="commission"
            value={commission}
            onChange={onChange}
          />
        </div>
        <div className="form-group">
          <p className="lead">Time required is {timeRequired} hours</p>
          <small className="form-text">
            This is a very simple approach. The first version is just to get
            something out.
          </small>
        </div>

        <input type="submit" className="btn btn-primary" value="Calculate" />
      </form>
      <p className="my-1">
        Already have an account? <Link to="/login">Sign In</Link> or{" "}
        <Link to="/register">Register</Link>
      </p>
    </Fragment>
  );
};

HowManyHours.propTypes = {
  // setAlert: PropTypes.func.isRequired,
  // register: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, {})(HowManyHours);
