import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import "../styles/event-form.scss";
import Resizer from "react-image-file-resizer";
import TextField from "@mui/material/TextField";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { Checkbox, FormControl, InputLabel, MenuItem } from "@mui/material";
import Select from "@mui/material/Select";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import api from "../../../utils/api";
import { Input, Space, Button, notification } from "antd";
import { useSelector } from "react-redux";
export default function BasicDetailsForm({
  onSubmit,
  defaultValues,
  UpdateEvent,
}) {
  const [Published, setPublished] = useState(defaultValues.publishedOn);
  const [isRecurring, setIsRecurring] = useState(defaultValues.isRecurring);
  const [Recurrence, setRecurrence] = useState(defaultValues.Recurrence);
  const [Dayofweek, setDayofweek] = useState(defaultValues.Dayofweek);
  const [Tags, setTags] = useState(defaultValues.Tags);
  const [checkInVal, setcheckInVal] = useState(defaultValues.checkIn);
  const [TagsValues, setTagsValues] = useState(defaultValues.TagsValues);
  const [inVT, setinVT] = useState("");
  const history = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues,
  });
  console.table({ defaultValues });
  const fileInputHasError = (files) => {
    if (files.length > 3) {
      setError("mediaList", { message: "Maximum 3 files are allowed" });
      return true;
    }
    const errorType = {
      size: false,
      type: false,
    };
    Array.from(files).forEach((file) => {
      if (!file.type.includes("image")) {
        errorType.type = true;
        return;
      } else if (file.size > 1e7) {
        errorType.size = true;
        return;
      }
    });
    if (errorType.size) {
      setError("mediaList", { message: "Maximum file size can be 10mb" });
      return true;
    }
    if (errorType.type) {
      setError("mediaList", { message: "Only images are allowed" });
      return true;
    }
    clearErrors("mediaList");
    return false;
  };
  const tobase64Handler = async (files) => {
    const filePathsPromises = [];
    Array.from(files).forEach((file) => {
      filePathsPromises.push(resizeFile(file));
    });
    const filePaths = await Promise.all(filePathsPromises);
    const mappedFiles = filePaths.map((base64File) => base64File);
    return mappedFiles;
  };
  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        300,
        400,
        "JPEG",
        80,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });
  const handleFileInputChange = async (e) => {
    const { files } = e.target;
    const Images = await tobase64Handler(files);
    if (!files.length) return;
    if (fileInputHasError(files)) setValue("mediaList", []);
    else setValue("mediaList", Array.from(Images));
  };

  const handleFileDrop = async (e) => {
    e.preventDefault();
    const { files } = e.dataTransfer;
    const Images = await tobase64Handler(files);
    if (fileInputHasError(files)) setValue("mediaList", []);
    else setValue("mediaList", Array.from(Images));
  };
  const handpublishedOnChange = (e) => {
    setValue("publishedOn", e.target.value);
    setPublished(e.target.value);
  };
  const checkInHandler = (e) => {
    setcheckInVal(e.target.value);
    setValue("checkIn", e.target.value);
  };
  const handleFormSubmit = (data) => {
    onSubmit(data);
  };
  const [TempDate, setTempDate] = useState(
    moment(defaultValues.eventStartDateAndTime)
  );
  const dateHandler = (date) => {
    setTempDate(date);
    setValue("eventStartDateAndTime", date);
  };
  const handTagsOnChange = (e) => {
    // setValue("Tags", e.target.value);
    // setTags(e.target.value);
    const newValue = e.target.value;
    // Check if both "speeddating" and "social" are attempted to be added
    if (
      (newValue.includes("speeddating") && newValue.includes("social")) ||
      (newValue.includes("social") && newValue.includes("speeddating"))
    ) {
      notification.error({
        message: "Reserved tags",
        description: "You can't add both 'speeddating' and 'social' tags.",
      });
    } else {
      // It's safe to update the tags
      setValue("Tags", newValue); // Assuming setValue is a function from a form library like React Hook Form
      setTags(newValue); // Update your state or form field
    }
  };
  const handIsRecurring = (e) => {
    setValue("isRecurring", e.target.value);
    setIsRecurring(e.target.value);
  };

  const daysOfWeekHandler = (e) => {
    setValue("Dayofweek", e.target.value);
    setDayofweek(e.target.value);
  };
  const RecurrenceHandler = (e) => {
    setValue("Recurrence", e.target.value);
    setRecurrence(e.target.value);
  };

  const [TempDateRs, setTempDateRs] = useState(
    moment(defaultValues.startRecurrence)
  );
  const dateHandlerRs = (date) => {
    setTempDateRs(date);
    setValue("startRecurrence", date);
  };

  const [TempDateRe, setTempDateRe] = useState(
    moment(defaultValues.endRecurrence)
  );
  const dateHandlerRe = (date) => {
    setTempDateRe(date);
    setValue("endRecurrence", date);
  };
  const [TempDateR, setTempDateR] = useState(moment(defaultValues.eventTime));
  const TimeHandler = (date) => {
    setTempDateR(date);
    setValue("eventTime", date);
  };
  const [WebsitesDta, setWebsitesDta] = useState([]);
  useEffect(() => {
    api
      .get("websites/my/publish")
      .then((res) => {
        setWebsitesDta(res.data);
      })
      .catch((err) => {
        console.log(err);
        setWebsitesDta([]);
      });
  }, []);
  const processTag = (tag) => {
    if (tag) {
      setTagsValues([...TagsValues, tag]);
    }
  };
  return (
    <form className="event-form " onSubmit={handleSubmit(handleFormSubmit)}>
      <div>
        <FormControl fullWidth variant="outlined">
          <InputLabel id="demo-multiple-name-label">Recurring Event</InputLabel>
          <Select
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"
            label="Recurring Event"
            value={isRecurring}
            {...register("isRecurring", { onChange: handIsRecurring })}
          >
            <MenuItem value={true}>Yes</MenuItem>
            <MenuItem value={false}>No</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div>
        <TextField
          variant="outlined"
          name="eventName"
          className="form-control"
          type="text"
          label="Event Name"
          {...register("eventName", { required: "Please provide Name" })}
        />
        {errors.eventName && (
          <div className="alert alert-danger">{errors?.eventName?.message}</div>
        )}
      </div>
      <div className="form-group">
        <TextField
          variant="outlined"
          className="form-control"
          multiline
          minRows={10}
          label="Event Description"
          {...register("description", {
            required: "Please provide event description",
          })}
        ></TextField>

        {errors.description && (
          <div className="alert alert-danger">
            {errors?.description?.message}
          </div>
        )}
      </div>
      {user?.is_siteadmin && (
        <div className="form-group">
          <FormControl fullWidth variant="outlined">
            <TextField
              variant="outlined"
              className="form-control"
              type="text"
              label="Organizer (Alias)"
            />
          </FormControl>
        </div>
      )}
      <div className="form-group">
        <FormControl fullWidth variant="outlined">
          <InputLabel id="demo-multiple-name-label">Published On</InputLabel>
          <Select
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"
            label="Published On"
            multiple
            value={Published}
            {...register("publishedOn", { onChange: handpublishedOnChange })}
          >
            <MenuItem value="juznow">Juznow</MenuItem>
            <MenuItem value="clublink">Clublink</MenuItem>
            {WebsitesDta?.map((website, index) => {
              return (
                <MenuItem key={website?._id} value={website?._id}>
                  {website?.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </div>
      <div className="form-group">
        <FormControl fullWidth variant="outlined">
          <InputLabel id="demo-multiple-name-label">Tags</InputLabel>
          <Select
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"
            label="Tags"
            multiple
            value={Tags}
            {...register("Tags", {
              onChange: handTagsOnChange,
              required: "Please Provide Tags",
            })}
          >
            {TagsValues.map((d) => (
              <MenuItem value={d}>
                {d.charAt(0).toUpperCase() + d.slice(1)}
              </MenuItem>
            ))}
            <div className="p-2">
              <Space.Compact style={{ width: "100%" }}>
                <Input
                  placeholder="Add tag"
                  onChange={(e) => {
                    setinVT(e.target.value);
                  }}
                  value={inVT}
                />
                <Button
                  type="primary"
                  onClick={() => {
                    processTag(inVT);
                    setinVT(""); // Optionally clear the input field after adding
                  }}
                >
                  Add
                </Button>
              </Space.Compact>
            </div>
          </Select>
        </FormControl>
        {errors.Tags && (
          <div className="alert alert-danger">{errors?.Tags?.message}</div>
        )}
      </div>

      <div className="form-group">
        <TextField
          variant="outlined"
          className="form-control"
          type="text"
          label="Event Location"
          {...register("location", { required: "Please provide location" })}
        />
        {errors.location && (
          <div className="alert alert-danger">{errors?.location?.message}</div>
        )}
      </div>
      <div className="form-group">
        <TextField
          variant="outlined"
          className="form-control"
          type="text"
          label="City"
          {...register("City", { required: "Please provide city" })}
        />
        {errors.City && (
          <div className="alert alert-danger">{errors?.City?.message}</div>
        )}
      </div>
      {(!isRecurring || UpdateEvent) && (
        <section>
          {" "}
          <div className="form-group mb-4">
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DateTimePicker
                renderInput={(props) => (
                  <TextField
                    {...props}
                    className="form-control"
                    {...register("eventStartDateAndTime", {
                      required: "Please provide event date and time",
                    })}
                  />
                )}
                label="Event start date&time"
                value={TempDate}
                minDate={moment()}
                // inputFormat="DD-MMM-YYYY hh:mm a"
                onChange={(newValue) => {
                  dateHandler(newValue);
                }}
              />
            </LocalizationProvider>
            {errors.eventStartDateAndTime && (
              <div className="alert alert-danger">
                {errors?.eventStartDateAndTime?.message}
              </div>
            )}
          </div>
          <div>
            <TextField
              variant="outlined"
              className="form-control"
              type="Number"
              name="eventDuration"
              min="1"
              max="24"
              InputProps={{
                inputProps: {
                  min: 1,
                },
              }}
              label="Event Duration In Hours"
              {...register("eventDuration", {
                required: "Please Provide Event Duration",
              })}
            />
            {errors.eventDuration && (
              <div className="alert alert-danger">
                {errors?.eventDuration?.message}
              </div>
            )}
          </div>
        </section>
      )}

      {isRecurring && !UpdateEvent && (
        <section className="rec-cmp-st">
          <div className="mb-4">
            <FormControl fullWidth variant="outlined">
              <InputLabel id="demo-multiple-name-label">Recurrence</InputLabel>
              <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                label="Recurrence"
                value={Recurrence}
                {...register("Recurrence", {
                  onChange: RecurrenceHandler,
                })}
              >
                <MenuItem value={"Weekly"}>Weekly</MenuItem>
                {/* <MenuItem value={"Monthly"}>Monthly</MenuItem>
                <MenuItem value={"Yearly"}>Yearly</MenuItem> */}
              </Select>
            </FormControl>
          </div>
          <div className="mb-4">
            <FormControl fullWidth variant="outlined">
              <InputLabel id="demo-multiple-name-label">
                Days of week
              </InputLabel>
              <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                label="Days of week"
                {...register("Dayofweek", { onChange: daysOfWeekHandler })}
                value={Dayofweek}
                multiple
              >
                <MenuItem value={1}>Monday</MenuItem>
                <MenuItem value={2}>Tuesday</MenuItem>
                <MenuItem value={3}>Wednesday</MenuItem>
                <MenuItem value={4}>Thursday</MenuItem>
                <MenuItem value={5}>Friday</MenuItem>
                <MenuItem value={6}>Saturday</MenuItem>
                <MenuItem value={7}>Sunday</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="form-group mb-4">
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                renderInput={(props) => (
                  <TextField
                    {...props}
                    className="form-control"
                    {...register("startRecurrence", {
                      required: "Please provide start date",
                    })}
                  />
                )}
                label="Start Recurrence"
                value={TempDateRs}
                minDate={moment()}
                // inputFormat="DD-MMM-YYYY hh:mm a"
                onChange={(newValue) => {
                  dateHandlerRs(newValue);
                }}
              />
            </LocalizationProvider>
          </div>
          <div className="form-group mb-4">
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                renderInput={(props) => (
                  <TextField
                    {...props}
                    className="form-control"
                    {...register("endRecurrence", {
                      required: "Please provide end date",
                    })}
                  />
                )}
                label="End Recurrence"
                value={TempDateRe}
                minDate={moment()}
                // inputFormat="DD-MMM-YYYY hh:mm a"
                onChange={(newValue) => {
                  dateHandlerRe(newValue);
                }}
              />
            </LocalizationProvider>
          </div>
          <div className="form-group mb-4">
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <TimePicker
                label="Event Time"
                value={TempDateR}
                onChange={(newValue) => TimeHandler(newValue)}
              />
            </LocalizationProvider>
          </div>
          <div>
            <TextField
              variant="outlined"
              className="form-control"
              type="Number"
              name="eventDuration"
              min="1"
              max="24"
              InputProps={{
                inputProps: {
                  min: 1,
                },
              }}
              label="Event Duration In Hours"
              {...register("eventDuration", {
                required: "Please Provide Event Duration",
              })}
            />
            {errors.eventDuration && (
              <div className="alert alert-danger">
                {errors?.eventDuration?.message}
              </div>
            )}
          </div>
        </section>
      )}
      <div>
        <TextField
          variant="outlined"
          name="directions"
          className="form-control"
          type="text"
          label="Directions (optional)"
          multiline
          minRows={3}
          {...register("directions")}
        />
      </div>
      <div>
        <TextField
          variant="outlined"
          name="locateUs"
          className="form-control"
          type="text"
          multiline
          minRows={3}
          label="Locate us (optional)"
          {...register("locateUs")}
        />
      </div>
      <div className="form-group">
        <label
          htmlFor="event-images"
          className="form-control text-muted text-center d-flex align-items-center justify-content-center event-form-files"
          onDragOver={(e) => e.preventDefault()}
          onDrop={handleFileDrop}
        >
          Drag & Drop upto 3 images
        </label>
        <input
          hidden
          id="event-images"
          type="file"
          label="mediaList"
          name="mediaList"
          multiple
          {...register("mediaList", { onChange: handleFileInputChange })}
        />
        {errors.mediaList && (
          <div className="alert alert-danger">{errors.mediaList?.message}</div>
        )}

        {watch("mediaList")?.length ? (
          <div className="row">
            {[...watch("mediaList")]?.map((media) => (
              <div key={media.name} className="col-4">
                <img src={media} alt={media.name} height={"200px"} />
              </div>
            ))}
          </div>
        ) : null}
      </div>

      <div className="d-flex justify-content-between">
        <button className="btn btn-danger" onClick={() => history(-1)}>
          Cancel
        </button>
        <button type="submit" className="btn btn-primary">
          Continue
        </button>
      </div>
    </form>
  );
}
