import React, { useState } from "react";
import { ChangePassword } from "../../actions/auth";
import { useDispatch } from "react-redux";
import TextField from "@mui/material/TextField";

const Changepassword = () => {
  const dispatch = useDispatch();
  const [old_password, setold_password] = useState("");
  const [new_password, setnew_password] = useState("");
  const [confirm_password, setconfirm_password] = useState("");
  const mb10 = {
    marginBottom: 10,
  };

  const submitPassword = () => {
    let obj = { old_password, new_password, confirm_password };
    dispatch(ChangePassword(obj));
    setold_password("");
    setnew_password("");
    setconfirm_password("");
  };
  return (
    <div>
      <section>
        <h1 className=" large text-primary" style={{ marginBottom: 20 }}>
          Change Password
        </h1>
        <div style={mb10}>
          <p>Update your password (Minimum 6 characters)</p>
          <TextField
            type="password"
            required
            label="Old Password"
            className="form-control"
            value={old_password}
            onChange={(e) => setold_password(e.target.value)}
          />
        </div>
        <div style={mb10}>
          <TextField
            type="password"
            required
            label="New Password"
            className="form-control"
            value={new_password}
            onChange={(e) => setnew_password(e.target.value)}
          />
        </div>
        <div style={mb10}>
          <TextField
            type="password"
            required
            label="Confirm password"
            className="form-control"
            value={confirm_password}
            onChange={(e) => setconfirm_password(e.target.value)}
          />
        </div>
        <input
          type="submit"
          className="btn btn-primary"
          value="Submit"
          style={{ height: "39px" }}
          onClick={() => submitPassword()}
        />
      </section>
    </div>
  );
};

export default Changepassword;
