import React, { useState } from "react";
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { setAlert } from "../../actions/alert";
import { register } from "../../actions/auth";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";

const Register = ({ setAlert, register, isAuthenticated }) => {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    password2: "",
  });

  const { first_name, last_name, email, password, password2 } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    if (password !== password2) {
      setAlert("Passwords do not match", "error");
    } else {
      register({ first_name, last_name, email, password });
    }
  };

  if (isAuthenticated) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <section>
      <h1 className="large text-primary">Sign Up</h1>
      <p className="lead">
        <i className="fas fa-user" /> Create Your Account
      </p>
      <form className="form" onSubmit={onSubmit}>
        <div className="form-group">
          <TextField
            type="text"
            label="First Name"
            className="form-control"
            name="first_name"
            value={first_name}
            onChange={onChange}
          />
        </div>
        <div className="form-group">
          <TextField
            type="text"
            label="Last Name"
            className="form-control"
            name="last_name"
            value={last_name}
            onChange={onChange}
          />
        </div>
        <div className="form-group">
          <TextField
            type="email"
            label="Email Address"
            className="form-control"
            name="email"
            value={email}
            onChange={onChange}
          />
        </div>
        <div className="form-group">
          <TextField
            type="password"
            label="Password"
            className="form-control"
            name="password"
            value={password}
            onChange={onChange}
          />
        </div>
        <div className="form-group">
          <TextField
            type="password"
            label="Confirm Password"
            className="form-control"
            name="password2"
            value={password2}
            onChange={onChange}
          />
        </div>
        <input type="submit" className="btn btn-primary" value="Register" />
      </form>
      <p className="my-1">
        Already have an account? <Link to="/login">Sign In</Link>
      </p>
    </section>
  );
};

Register.propTypes = {
  setAlert: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { setAlert, register })(Register);
