import { TextField, Button, Modal, Box } from "@mui/material";
import React, { useState } from "react";
import api from "../../utils/api";
import { useDispatch } from "react-redux";
import { setAlert } from "../../actions/alert";

const MyGroup = () => {
  const Gbtn = {
    width: 200,
    fontWeight: "600",
    color: "#18a2b8",
    cursor: "pointer",
    borderColor: "#18a2b8",
    height: 56,
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();
  const onSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData.entries());
    console.log(data);
    let obj = {
      lat: 12,
      lng: 12,
      group_type: "fitness",
      is_private: false,
      ...data,
    };
    api
      .post("/group", obj)
      .then((res) => {
        console.log(res);
        if (res.data.status) {
          handleClose();
        } else if (!res.data.status) {
          dispatch(setAlert(res.data.message, "error"));
        }
      })
      .catch((err) => {
        dispatch(setAlert(err?.message, "error"));
      });
  };
  return (
    <div>
      <h1 className="text-primary mb-4">My Groups</h1>
      <section className="d-flex gap-2 flex-wrap">
        <TextField
          label="Search Groups"
          variant="outlined"
          style={{
            width: 300,
          }}
        />
        <Button variant="outlined" style={Gbtn} onClick={handleOpen}>
          Create Group
        </Button>
        <Modal open={open} onClose={handleClose}>
          <Box sx={style}>
            <div>
              <div className="d-flex justify-content-between mb-4">
                <h4>Create Group</h4>
                <div className="cursor-pointer" onClick={() => handleClose()}>
                  X
                </div>
              </div>
              <form className="form" onSubmit={onSubmit}>
                <div className="form-group mb-4">
                  <TextField
                    variant="outlined"
                    label="Group title"
                    className="form-control"
                    name="title"
                    required
                  />
                </div>
                <div className="form-group mb-4">
                  <TextField
                    variant="outlined"
                    label="Group description"
                    className="form-control"
                    name="description"
                    required
                  />
                </div>
                <div className="form-group mb-4">
                  <TextField
                    variant="outlined"
                    label="location"
                    className="form-control"
                    name="location"
                    required
                  />
                </div>
                <div className="d-flex justify-content-end">
                  <Button type="submit" variant="outlined" style={Gbtn}>
                    Create Group
                  </Button>
                </div>
              </form>
            </div>
          </Box>
        </Modal>
      </section>
    </div>
  );
};

export default MyGroup;
