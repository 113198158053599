import React, { Fragment, useEffect, useState } from "react";
import QrReader from "modern-react-qr-reader";
import api from "../../utils/api";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import Spinner from "../layout/Spinner";

const status = {
  pending: "payment-pending",
  used: "used",
  valid: "valid",
};

export default function QrScanner() {
  const history = useNavigate();
  const { id } = useParams();
  const [event, setEvent] = useState();
  const [reservationDetails, setReservationDetails] = useState();
  const [loading, setLoading] = useState(false);
  const [MainLoad, setMainLoad] = useState(true);
  const [errMsg, setErrorMsg] = useState(null);
  const [showMenu, setShowMenu] = useState(false);
  const { user } = useSelector((state) => state.auth);

  const handleOnResult = async (result) => {
    setLoading(true);
    setErrorMsg(null);
    try {
      const { data } = await api.get(`/reserve/${result}?event=${id}`);
      setReservationDetails(data);
      setLoading(false);
    } catch (err) {
      if (err.response && err.response.data)
        setErrorMsg(err.response.data.message);
      else setErrorMsg("Something went wrong while scanning code.");
      setLoading(false);
    }
  };

  const handleAction = async (status) => {
    try {
      await api.patch(`/reserve/${reservationDetails._id}`, { status });
      handleOnResult(reservationDetails._id);
    } catch (err) {
      if (err.response && err.response.data)
        setErrorMsg(err.response.data.message);
      else setErrorMsg("Something went wrong while scanning code.");
    }
  };

  useEffect(() => {
    api
      .get(`/events/${id}`)
      .then(({ data }) => {
        setEvent(data);
        setTimeout(() => {
          setMainLoad(false);
        }, 300);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [id]);

  return (
    <section>
      {MainLoad && <Spinner />}
      {!MainLoad && (
        <Fragment>
          {event?.createdBy?._id === user?._id ||
          event?.Access.includes(user?.email) ||
          user?.is_siteadmin ? (
            <Fragment>
              <div className="web-scanner-main-outer scanner-day">
                <div className="dropdown-time-right">
                  <div
                    onMouseEnter={() => setShowMenu(true)}
                    onMouseLeave={() => setShowMenu(false)}
                  >
                    <div className="dropdown" style={{ zIndex: 2 }}>
                      <div className="dropdown-arrow">
                        <svg
                          width="36"
                          height="36"
                          viewBox="0 0 36 36"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          onClick={() => setShowMenu(true)}
                        >
                          <circle cx="18" cy="18" r="17.5" stroke="#B9B9B9" />
                          <path
                            d="M17.4688 21.9375C17.75 22.2188 18.2188 22.2188 18.5 21.9375L24.5938 15.875C24.875 15.5625 24.875 15.0938 24.5938 14.8125L23.875 14.0938C23.5938 13.8125 23.125 13.8125 22.8125 14.0938L18 18.9062L13.1562 14.0938C12.8438 13.8125 12.375 13.8125 12.0938 14.0938L11.375 14.8125C11.0938 15.0938 11.0938 15.5625 11.375 15.875L17.4688 21.9375Z"
                            fill="#565656"
                          />
                        </svg>
                      </div>
                      <ul
                        className={
                          showMenu ? " dropdown-menu show" : "dropdown-menu"
                        }
                        aria-labelledby="dropdownMenu2"
                      >
                        <li>
                          <button
                            onClick={() => history(-1)}
                            className="dropdown-item"
                            type="button"
                          >
                            Cancel
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="event-title">{event?.eventName}</div>
                <div className="date-time-outer">
                  <span className="date">
                    {" "}
                    {moment(event?.eventStartDateAndTime).format("DD/MM/YYYY")}
                  </span>
                  <span className="time">
                    {" "}
                    {moment(event?.eventStartDateAndTime).format("LT")} -{" "}
                    {moment(event?.eventStartDateAndTime)
                      .add(event?.eventDuration, "hours")
                      .format("LT")}{" "}
                    ({event?.eventDuration}hrs)
                  </span>
                </div>
                <div className="scanner-valid-row">
                  <div className="col-12 col-md-5  pb-3">
                    <QrReader
                      onError={(error) => {
                        console.error(error);
                      }}
                      onScan={(result) => {
                        if (!!result) {
                          handleOnResult(result);
                        }
                      }}
                      style={{ width: "100%" }}
                      facingMode="environment"
                    />
                  </div>
                  <div className="valid-box-left">
                    {!reservationDetails ? (
                      <Fragment>
                        <h3>
                          Please scan a QR code <br />
                          for reservation details
                        </h3>
                        {loading && (
                          <div className="alert alert-info">
                            Please wait while we fetch reservation details
                          </div>
                        )}
                        {errMsg && (
                          <div className="alert alert-danger">{errMsg}</div>
                        )}
                      </Fragment>
                    ) : (
                      <Fragment>
                        <div className="valid-title d-flex justify-content-center">
                          {reservationDetails.status === "valid" && (
                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                              >
                                <path d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z" />
                              </svg>
                            </div>
                          )}
                          <div style={{ marginLeft: 10 }}>
                            {reservationDetails.status === "used" ? (
                              <span style={{ color: "red" }}>USED</span>
                            ) : (
                              reservationDetails.status.toUpperCase()
                            )}
                          </div>
                        </div>
                        <div className="user-name">
                          {reservationDetails.reservedBy.name}
                          {reservationDetails.quantity > 1
                            ? ` (+${reservationDetails.quantity})`
                            : ""}
                        </div>
                        <div className="eent-fee">
                          FEE: $
                          {reservationDetails.event.Reservation.price *
                            reservationDetails.quantity}
                        </div>
                        {reservationDetails.status === "valid" && (
                          <button
                            className="btn btn-primary"
                            style={{ width: 300 }}
                            onClick={() => handleAction(status.used)}
                          >
                            USE NOW
                          </button>
                        )}
                        {reservationDetails.status === "used" && (
                          <button
                            className="btn btn-primary"
                            style={{ width: 300 }}
                            onClick={() => handleAction(status.valid)}
                          >
                            MARK IT UNUSED
                          </button>
                        )}
                      </Fragment>
                    )}
                  </div>
                </div>
              </div>
            </Fragment>
          ) : (
            <div className="d-flex">
              <div className="pt-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="white"
                  class="bi bi-x-octagon-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M11.46.146A.5.5 0 0 0 11.107 0H4.893a.5.5 0 0 0-.353.146L.146 4.54A.5.5 0 0 0 0 4.893v6.214a.5.5 0 0 0 .146.353l4.394 4.394a.5.5 0 0 0 .353.146h6.214a.5.5 0 0 0 .353-.146l4.394-4.394a.5.5 0 0 0 .146-.353V4.893a.5.5 0 0 0-.146-.353L11.46.146zm-6.106 4.5L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708z" />
                </svg>
              </div>

              <div className="page-title" style={{ marginLeft: 10 }}>
                If you are seeing this, you are probably took a wrong turn. Try
                logging out and logging back in.
              </div>
            </div>
          )}
        </Fragment>
      )}
    </section>
  );
}
