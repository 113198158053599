import React, { Fragment } from "react";
import { Route, Routes } from "react-router-dom";
import Landing from "../layout/Landing";
import Register from "../auth/Register";
import HowManyHours from "../tools/HowManyHours";
import Login from "../auth/Login";
import Dashboard from "../dashboard/Dashboard";
import ResetPassword from "../auth/ResetPassword";
import ProfileForm from "../profile-forms/ProfileForm";
import AddExperience from "../profile-forms/AddExperience";
import AddEducation from "../profile-forms/AddEducation";
import Profiles from "../profiles/Profiles";
import Profile from "../profile/Profile";
import Posts from "../posts/Posts";
import Post from "../post/Post";
import NotFound from "../layout/NotFound";
import Account from "../Account";
import Changepassword from "../auth/ChangePassword";
import MyReservation from "../Reservation/MyReservation";
import ReservationView from "../Reservation/ReservationView";
import Subscriptions from "../Subscriptions";

//test
// import ScrollingPosts from '../test/ScrollingPosts';
// import InfiniteScrollExample from '../test/InfiniteScrollExample';

import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import EditPost from "../posts/EditPost";
import AllEvents from "../events/AllEvents";
import CreateEvent from "../events/CreateEvent";
import UpdateEvent from "../events/UpdateEvent";
import EventDetails from "../events/EventDetails";
import EventStats from "../events/EventStats";
import Payments from "../events/Payments";
import EventDashboard from "../events/EventDashboard";
import VipReservations from "../events/EventForm/VipReservationsForm";
import AddPrepaidChannelsForm from "../events/EventForm/AddPrepaidChannelsForm";
import QrScanner from "../events/QrReader";
import ManageEvent from "../events/ManageEvent";
import CreatePoll from "../Poll/CreatePoll";
import PollList from "../Poll/PollList";
import ViewPoll from "../Poll/ViewPoll";
import MyGroup from "../Groups/MyGroup";
import Websites from "../Websites";
import Ads from "../Ads";
import WebsiteForm from "../Websites/WebsiteForm";
const RouteConfig = () => {
  return (
    <Routes>
      <Route exact path="/register" element={<Register />} />
      <Route exact path="/login" element={<Login />} />
      <Route path="reset-password" element={<ResetPassword />} />
      <Route component={<NotFound />} />
      <Route
        exact
        path="/my-groups"
        element={<PrivateRoute component={MyGroup} />}
      />
      <Route
        exact
        path="/website-list"
        element={<PrivateRoute component={Websites} />}
      />
      <Route
        exact
        path="/website/:id/ads"
        element={<PrivateRoute component={Ads} />}
      />
      <Route
        exact
        path="/website"
        element={<PrivateRoute component={WebsiteForm} />}
      />
      <Route
        exact
        path="/website/:id"
        element={<PrivateRoute component={WebsiteForm} />}
      />
      <Route
        exact
        path="/events"
        element={<PrivateRoute component={AllEvents} />}
      />
      <Route
        exact
        path="/events/dashboard"
        element={<PrivateRoute component={EventDashboard} />}
      />{" "}
      <Route
        exact
        path="/events/payments"
        element={<PrivateRoute component={Payments} />}
      />{" "}
      <Route
        exact
        path={"/events/:id/add-reservation"}
        element={<PrivateRoute component={VipReservations} />}
      />{" "}
      <Route
        exact
        path={"/events/:id/add-third-party-ticket-provider"}
        element={<PrivateRoute component={AddPrepaidChannelsForm} />}
      />{" "}
      <Route
        exact
        path="/events/:id/manage"
        element={<PrivateRoute component={ManageEvent} />}
      />
      <Route
        exact
        path="/events/:id/stats"
        element={<PrivateRoute component={EventStats} />}
      />{" "}
      <Route
        exact
        path="/events/:id/scanner"
        element={<PrivateRoute component={QrScanner} />}
      />{" "}
      <Route
        exact
        path={"/events/:id/update"}
        element={<PrivateRoute component={UpdateEvent} />}
      />
      <Route exact path="/events/:id" element={<EventDetails />} />
      {/* <Route exact path="/posts" element={<PrivateRoute component={Posts} />} />
      <Route
        exact
        path="/posts/:id"
        element={<PrivateRoute component={Post} />}
      /> */}
      <Route
        exact
        path="/dashboard"
        element={<PrivateRoute component={Dashboard} />}
      />{" "}
      <Route
        exact
        path="/my-reservations"
        element={<PrivateRoute component={MyReservation} />}
      />{" "}
      <Route
        exact
        path="/my-reservations/:id"
        element={<PrivateRoute component={ReservationView} />}
      />{" "}
      <Route
        exact
        path="/create-profile"
        element={<PrivateRoute component={ProfileForm} />}
      />{" "}
      <Route
        exact
        path="/edit-profile"
        element={<PrivateRoute component={ProfileForm} />}
      />{" "}
      <Route
        exact
        path="/add-experience"
        element={<PrivateRoute component={AddExperience} />}
      />{" "}
      <Route
        exact
        path="/add-education"
        element={<PrivateRoute component={AddEducation} />}
      />{" "}
      <Route
        exact
        path="/posts/:id/edit"
        element={<PrivateRoute component={EditPost} />}
      />{" "}
      <Route
        exact
        path={"/events/create"}
        element={<PrivateRoute component={CreateEvent} />}
      />{" "}
      <Route
        exact
        path={"/events/create/poll/:pollId/option/:pollOpId/"}
        element={<PrivateRoute component={CreateEvent} />}
      />{" "}
      <Route
        exact
        path="/account-settings"
        element={<PrivateRoute component={Account} />}
      />
      <Route
        exact
        path="/subscriptions"
        element={<PrivateRoute component={Subscriptions} />}
      />
      <Route
        exact
        path="change-password"
        element={<PrivateRoute component={Changepassword} />}
      />
      <Route
        exact
        path="/poll"
        element={<PrivateRoute component={CreatePoll} />}
      />
      <Route
        exact
        path="/poll-list"
        element={<PrivateRoute component={PollList} />}
      />
      <Route exact path="/poll/:id" element={<ViewPoll />} />
      <Route
        exact
        path="/howmanyhours"
        element={<PublicRoute component={HowManyHours} />}
      />
      <Route exact path="/profiles" element={<Profiles />} />
      <Route exact path="/profile/:id" element={<Profile />} />
      <Route exact path="/u/:user" element={<Profile />} />
      <Route exact path="/" element={<Landing />} />
    </Routes>
  );
};

export default RouteConfig;
