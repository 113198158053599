import React from "react";
import Dialog from "../Dialog";
import PropTypes from "prop-types";
import EventCard from "./EventCard";

export default function EventPreview({ open, onClose, event }) {
  return (
    <Dialog open={open} onClose={onClose}>
      {/* <pre>{JSON.stringify(event, null, 4)}</pre> */}

      {/* <EventItem event={event} showForms preview /> */}
      <EventCard event={event} showForms preview route="details" />
    </Dialog>
  );
}

EventPreview.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
