import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  DatePicker,
  TimePicker,
  Select,
  InputNumber,
  notification,
} from "antd";
import api from "../../../utils/api";
import { useSelector } from "react-redux";

const { Option } = Select;

const RecurringEventForm = (onSubmit) => {
  const { user } = useSelector((state) => state.auth);
  const [form] = Form.useForm();
  const onFinish = async (values) => {
    if (values) {
      await api
        .post("/events/recurring", values)
        .then((res) => {
          notification.success({
            message: "Recurring Event has been created successfully",
          });
        })
        .catch((err) => {
          notification.error({
            message: "Failed to create recurring event",
          });
        });
    }
  };
  const [WebsitesDta, setWebsitesDta] = useState([]);
  useEffect(() => {
    api
      .get("/websites/my/publish")
      .then((res) => {
        setWebsitesDta(res.data);
      })
      .catch((err) => {
        console.log(err);
        setWebsitesDta([]);
      });
  }, []);
  const handleWebsiteChange = (value) => {
    const website = WebsitesDta.find((web) => web._id === value);
    form.setFieldsValue({
      City: website.city,
    });
  };

  return (
    <div>
      <Form layout="vertical" onFinish={onFinish} form={form}>
        <Form.Item
          name="Title"
          label="Title"
          rules={[
            {
              required: true,
              message: "Please input the title!",
            },
          ]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item name="description" label="Description">
          <Input.TextArea size="large" />
        </Form.Item>
        <Form.Item
          name="website_id"
          label="Publish to website"
          rules={[
            {
              required: true,
              message: "Please select a website!",
            },
          ]}
        >
          <Select size="large" onChange={handleWebsiteChange}>
            {WebsitesDta?.map((website, index) => {
              return (
                <Select.Option key={website?._id} value={website?._id}>
                  {website?.name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        {user?.is_siteadmin && (
          <Form.Item name="Organizer" label="Organizer (Alias)">
            <Input size="large" />
          </Form.Item>
        )}
        <Form.Item name="Venue" label="Venue">
          <Input size="large" />
        </Form.Item>
        <Form.Item name="address" label="Address">
          <Input size="large" />
        </Form.Item>
        <Form.Item name="City" label="City">
          <Input
            size="large"
            disabled={true}
            value={form.getFieldValue("city")}
          />
        </Form.Item>
        <Form.Item name="State" label="State">
          <Input size="large" />
        </Form.Item>
        <Form.Item name="Country" label="Country">
          <Input size="large" />
        </Form.Item>
        <Form.Item
          name="Recurrence"
          label="Recurrence"
          rules={[
            {
              required: true,
              message: "Please select a recurrence pattern!",
            },
          ]}
        >
          <Select size="large">
            <Option value="Weekly">Weekly</Option>
            {/* <Option value="Monthly">Monthly</Option>
            <Option value="Yearly">Yearly</Option> */}
          </Select>
        </Form.Item>
        <Form.Item
          name="Dayofweek"
          label="Day of Week"
          rules={[
            {
              required: true,
              message: "Please select a day of the week!",
            },
          ]}
        >
          <Select size="large" mode="multiple">
            <Option value={1}>Monday</Option>
            <Option value={2}>Tuesday</Option>
            <Option value={3}>Wednesday</Option>
            <Option value={4}>Thursday</Option>
            <Option value={5}>Friday</Option>
            <Option value={6}>Saturday</Option>
            <Option value={7}>Sunday</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="FromDate"
          label="From Date"
          rules={[
            {
              required: true,
              message: "Please select a start date!",
            },
          ]}
        >
          <DatePicker size="large" />
        </Form.Item>
        <Form.Item
          name="ToDate"
          label="To Date"
          rules={[
            {
              required: true,
              message: "Please select an end date!",
            },
          ]}
        >
          <DatePicker size="large" />
        </Form.Item>
        <Form.Item
          name="Time"
          label="Time"
          rules={[
            {
              required: true,
              message: "Please select a time!",
            },
          ]}
        >
          <TimePicker format="h:mm a" size="large" />
        </Form.Item>
        <Form.Item
          name="Duration"
          label="Duration (IN HOURS)"
          rules={[
            {
              required: true,
              message: "Please input the duration!",
            },
          ]}
        >
          <InputNumber size="large" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            x Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default RecurringEventForm;
