import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { Link, NavLink } from "react-router-dom";
// import formatDate from "../../utils/formatDate";
import { connect } from "react-redux";
import { addLike, removeLike, deletePost } from "../../actions/post";

import "./post-item.scss";

const PostItem = ({
  addLike,
  removeLike,
  deletePost,
  auth,
  post: { _id, link, text, name, avatar, user, likes, comments, date },
  showActions,
}) => {
  const [showMenu, setShowMenu] = useState(false);
  return (
    <>
      <div className="post-item p-3 my-3">
        <div className="post-item-body">
          <div className="post-item-body-content">
            <p className="post-item-body-content-text">{text}</p>
            <p className="post-item-body-content-author">
              <Link to={`/profile/${user}/${name.replaceAll(" ", "")}`}>
                <span>{name} </span>
              </Link>
              ({new Date(date).toDateString()})
            </p>
          </div>
          <div
            className="post-item-body-avatar"
            onClick={() => setShowMenu(!showMenu)}
          >
            <span> {name?.charAt(0).toUpperCase()} </span>
            {showMenu && (
              <div
                className="post-item-body-menu"
                onClick={(e) => e.stopPropagation()}
              >
                <span className="post-item-body-menu-item">Share</span>

                {!auth.loading && auth.user && user === auth.user._id && (
                  <>
                    <NavLink
                      to={`/posts/${_id}/edit`}
                      className="post-item-body-menu-item"
                    >
                      Edit
                    </NavLink>
                    <button
                      onClick={() => deletePost(_id)}
                      className="post-item-body-menu-item"
                    >
                      Delete
                    </button>
                  </>
                )}
              </div>
            )}
          </div>
        </div>

        <div className="post-item-footer">
          {showActions && (
            <Fragment>
              <button
                onClick={() => addLike(_id)}
                type="button"
                className="btn btn-light"
              >
                <i className="fas fa-thumbs-up" />{" "}
                <span>{likes.length > 0 && <span>{likes.length}</span>}</span>
              </button>
              <button
                onClick={() => removeLike(_id)}
                type="button"
                className="btn btn-light"
              >
                <i className="fas fa-thumbs-down" />
              </button>
              <Link to={`/posts/${_id}`} className="btn btn-primary">
                Comments{" "}
                {comments.length > 0 && (
                  <span className="comment-count">{comments.length}</span>
                )}
              </Link>
            </Fragment>
          )}
        </div>
      </div>
    </>
  );
};

PostItem.defaultProps = {
  showActions: true,
};

PostItem.propTypes = {
  post: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  addLike: PropTypes.func.isRequired,
  removeLike: PropTypes.func.isRequired,
  deletePost: PropTypes.func.isRequired,
  showActions: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { addLike, removeLike, deletePost })(
  PostItem
);
