import moment from "moment";
import React, { useState, useEffect, Fragment } from "react";
import { useNavigate, useParams } from "react-router";
import api from "../../utils/api";
import Spinner from "../layout/Spinner";

const ReservationView = () => {
  const { id } = useParams();
  const history = useNavigate();
  const [ticketdata, setticketdata] = useState(null);
  const [Loading, setLoading] = useState(true);

  useEffect(() => {
    api
      .get(`/reserve/my-reservation/${id}`)
      .then((res) => {
        setticketdata(res.data);
        setTimeout(() => {
          setLoading(false);
        }, 300);
      })
      .catch((err) => {});
  }, [id]);
  return (
    <Fragment>
      <div className="page-title-back-btn-outer mb-5">
        <div className="back-btn pointer-cursor" onClick={() => history(-1)}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
            <path d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z" />
          </svg>
          Back
        </div>
      </div>
      {Loading && <Spinner />}
      {!Loading && (
        <div>
          <div className="pdf-ticket-main-outer">
            <div className="pdf-left-vertical-box">
              <span>JUZNOW.COM</span>
            </div>
            <div className="pdf-middle-content">
              <div className="event-title">{ticketdata?.event?.eventName}</div>
              <div className="date-time-outer">
                <span className="date">
                  {" "}
                  {moment(ticketdata?.event?.eventStartDateAndTime).format(
                    "DD/MM/YYYY"
                  )}
                </span>
                <span className="time">
                  {" "}
                  {moment(ticketdata?.event?.eventStartDateAndTime).format(
                    "LT"
                  )}{" "}
                  -{" "}
                  {moment(ticketdata?.event?.eventStartDateAndTime)
                    .add(ticketdata?.event?.eventDuration, "hours")
                    .format("LT")}{" "}
                  ({ticketdata?.event?.eventDuration}hrs)
                </span>
              </div>
              <div className="full-address">{ticketdata?.event?.location}</div>
              <div className="pdf-middle-t-qr-sec">
                <div className="pdf-ticket-number-h">
                  {ticketdata?.ticket.H}
                  {ticketdata?.ticket.N}
                </div>
                <div className="qr-code-box">
                  <span className="qr-code-box-scan">SCAN FOR PRICE</span>
                  <img src={ticketdata?.qrCode} />
                  <span className="qr-code-box-juz">ON JUZNOW</span>
                </div>
              </div>
              <div className="pdf-bottom-sec">
                <div className="pdf-vaild-title">Valid for</div>
                <div className="pdf-vaild-name">
                  {ticketdata?.reservedBy?.name}{" "}
                  {ticketdata?.quantity > 1
                    ? `(+${ticketdata.quantity})`
                    : `(${ticketdata?.quantity})`}
                </div>
                <div className="pdf-vaild-reservation">
                  Reservation Only. Ticket Not Paid
                </div>
              </div>
            </div>
            <div className="pdf-right-vertical-box">
              <div className="pdf-ticket-number">
                <span className="pdf-ticket-number01">RESERVATION NUMBER</span>
                <span className="pdf-ticket-number02">
                  {ticketdata?.ticket.H}
                  {ticketdata?.ticket.N}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default ReservationView;
