import React, { Fragment, useCallback, useEffect, useState } from "react";
import api from "../../utils/api";
import "./styles/events.scss";
import EventCard from "./EventCard";
import { useNavigate } from "react-router";
import Spinner from "../layout/Spinner";
import { Radio } from "antd";

const limit = 300;

export default function AllEvents({ route, user }) {
  const history = useNavigate();
  const [events, setEvents] = useState([]);
  const [ZREvent, setZREvent] = useState([]);
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(true);
  const [et, setet] = useState("s");

  const getEvents = useCallback(async (page = 1) => {
    const { data } = await api.get("/events", {
      params: {
        limit,
        page,
      },
    });
    setPagination((prev) => ({ ...prev, ...data.pagination }));
    setEvents((prev) => {
      const newEvents = data.events.filter(
        (newEvent) => !prev.some((prevEvent) => prevEvent.id === newEvent.id)
      );
      return prev.concat(newEvents);
    });
    setTimeout(() => {
      setLoading(false);
    }, 300);
  }, []);
  const getPublic = useCallback(async () => {
    const { data } = await api.get(`/profile/${user}`, {});
    setEvents(data.events);
    setTimeout(() => {
      setLoading(false);
    }, 300);
  }, []);
  useEffect(() => {
    if (route === "profile") {
      getPublic();
    } else {
      getEvents();
    }
  }, []);

  const onDeleteClick = async (id) => {
    try {
      await api.delete(`/events/${id}`);
      setEvents((prev) => prev.filter((event) => event._id !== id));
    } catch (err) {}
  };

  const getPastEvents = useCallback(async (page = 1) => {
    try {
      const { data } = await api.get("/events/my-pastEvents", {
        params: {
          limit,
          page,
        },
      });
      // setPagination((prev) => ({ ...prev, ...data }));
      setZREvent((prev) => prev.concat(data?.events));
      setTimeout(() => {
        setLoading(false);
      }, 300);
    } catch (err) {
      setLoading(false);
    }
  }, []);
  useEffect(() => {
    setLoading(true);
    setEvents([]);
    setZREvent([]);

    if (et === "r") {
      getPastEvents();
    } else {
      getEvents();
    }
  }, [et]);

  console.log("ZREvent", ZREvent);

  return (
    <div className="events">
      {loading && <Spinner />}
      {!loading && (
        <Fragment>
          {route !== "profile" && (
            <div className="page-title-back-btn-outer">
              <div
                className="back-btn pointer-cursor"
                onClick={() => history(-1)}
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                  <path d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z" />
                </svg>
                Back
              </div>
              <div className="page-title mb-3">My Events</div>
              <div className="d-flex justify-content-center">
                <Radio.Group
                  value={et}
                  onChange={(e) => setet(e.target.value)}
                  style={{ marginBottom: 16 }}
                  size="large"
                >
                  <Radio.Button value="s">Upcoming Event</Radio.Button>
                  <Radio.Button value="r">Past Event</Radio.Button>
                </Radio.Group>
              </div>
            </div>
          )}
          {et === "s" && !events.length && !loading && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh",
              }}
            >
              You have not created any events!
            </div>
          )}
          {et === "r" && !ZREvent?.length && !loading && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh",
              }}
            >
              You do not have any past events!
            </div>
          )}
          {et === "s" &&
            events?.map((event, idx) => (
              <div className="pointer-cursor">
                <EventCard
                  event={event}
                  key={event?._id + idx}
                  onDeleteClick={onDeleteClick}
                  from={"list"}
                  route={route}
                />
              </div>
            ))}
          {et === "r" &&
            ZREvent?.map((event, idx) => (
              <div className="pointer-cursor">
                <EventCard
                  event={event}
                  key={event?._id + idx}
                  onDeleteClick={onDeleteClick}
                  from={"list"}
                  route={route}
                />
              </div>
            ))}
        </Fragment>
      )}
    </div>
  );
}
