import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import PostForm from "../PostForm";
import { getPost } from "../../../actions/post";
import api from "../../../utils/api";

export default function EditPost() {
  const { id } = useParams();
  const history = useNavigate();
  const dispatch = useDispatch();
  const { post, loading } = useSelector((state) => state.post);
  const { user } = useSelector((state) => state.auth);
  const [updateError, setUpdateError] = useState(null);

  useEffect(() => {
    dispatch(getPost(id));
  }, [dispatch, id]);

  const handleSubmit = async (data) => {
    setUpdateError(null);
    try {
      await api.patch(`/posts/${id}`, data);
      history("/posts");
    } catch (err) {
      if (err.response && err?.response?.data && err?.response?.data?.message)
        setUpdateError(err?.response?.data?.message);
      else setUpdateError("Failed to update");
    }
  };

  if (loading)
    return (
      <div>
        <h1>Loading...</h1>
      </div>
    );

  if (!loading && post && user._id !== post.user)
    return (
      <div>
        <h1>Only post author can edit a post</h1>
      </div>
    );

  return (
    <div>
      <PostForm
        initialText={post?.text}
        initialLink={post?.link}
        handleFormSubmit={handleSubmit}
      />

      {updateError && <div className="bg-danger p-1">{updateError}</div>}
    </div>
  );
}
