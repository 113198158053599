import React from "react";
export default function EventStats({ stats }) {
  return (
    <div className="">
      <div>
        <div className="booking-info-title">Booking Information</div>
        <div className="event-statistics-items">
          <div className="event-statistics-items-left">
            {/* <div className="statistics-item-row">
              <span className="statistics-name">Page View</span>
              <span className="statistics-number">{stats?.eventView}</span>
            </div> */}
            <div className="statistics-item-row">
              <span className="statistics-name">Inventory</span>
              <span className="statistics-number">
                {" "}
                {stats?.total ? stats?.total : 0}
              </span>
            </div>
            <div className="statistics-item-row">
              <span className="statistics-name">Reservations</span>
              <span className="statistics-number">
                {stats?.used + stats?.valid ? stats?.used + stats?.valid : 0}
              </span>
            </div>
          </div>
          <div className="event-statistics-items-right">
            <div className="statistics-item-row">
              <span className="statistics-name">Fulfilled</span>
              <span className="statistics-number">
                {stats?.used ? stats?.used : 0}
              </span>
            </div>
            <div className="statistics-item-row">
              <span className="statistics-name">Cancellations</span>
              <span className="statistics-number">
                {stats?.cancelled ? stats?.cancelled : 0}
              </span>
            </div>
            <div className="statistics-item-row">
              <span className="statistics-name">Outstanding</span>
              <span className="statistics-number">{stats?.valid}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
