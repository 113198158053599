import React, { useState, useEffect, Fragment } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import api from "../../utils/api";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import Spinner from "../layout/Spinner";

const MyReservation = () => {
  const history = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const [MyReservations, setMyReservations] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    api
      .get(`/reserve/${user?._id}/my-reservation`)
      .then((res) => {
        setMyReservations(res.data.events);
        setTimeout(() => {
          setLoading(false);
        }, 300);
      })
      .catch((err) => {
        setTimeout(() => {
          setLoading(false);
        }, 300);
      });
  }, [user]);
  return (
    <div>
      {loading && <Spinner />}
      {!loading && (
        <Fragment>
          <div className="page-title-back-btn-outer">
            <div
              className="back-btn  pointer-cursor"
              onClick={() => history(-1)}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                <path d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z" />
              </svg>
              Back
            </div>
            <div className="page-title">
              {MyReservations?.length > 0 && `My Reservations`}
              {MyReservations?.length === 0 &&
                !loading &&
                `You do not have
          any reservations yet`}
            </div>
          </div>
          {MyReservations?.map((res) => {
            return (
              <section>
                <div className="my-reservations-outer">
                  <div className="reservatios-item">
                    <div className="reserve-title-btns-row">
                      <div className="reserve-title-o">
                        <div className="booked-date">
                          Booked on date :{" "}
                          {moment(res.createdAt).format("DD/MM/YYYY")}{" "}
                        </div>
                        <div className="for-by">
                          For: {res.event?.createdBy?.display_name}{" "}
                          {res.quantity > 1 ? `(+${res.quantity} Guests)` : " "}
                        </div>
                      </div>
                      <div className="reserve-btn-o">
                        <Link to={`/my-reservations/${res._id}`}>
                          View Reservation
                        </Link>
                        {/* <a href="#">Cancel Reservation</a> */}
                      </div>
                    </div>
                    <div className="event-name-time">
                      <Link to={`/events/${res?.event?._id}`}>
                        {res.event?.eventName}
                      </Link>{" "}
                      <span>
                        {" "}
                        {moment(res?.event?.eventStartDateAndTime).format(
                          "DD/MM/YYYY"
                        )}{" "}
                        {moment(res?.event?.eventStartDateAndTime)
                          .add(res?.event?.eventDuration, "hours")
                          .format("LT")}{" "}
                        ({res?.event?.eventDuration}hrs)
                      </span>
                    </div>
                  </div>
                </div>
              </section>
            );
          })}
        </Fragment>
      )}
    </div>
  );
};

export default MyReservation;
