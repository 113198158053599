import React, { Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
const EventSelector = ({ event }) => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const { showStat } = useSelector((state) => state.event);
  const { user } = useSelector((state) => state.auth);
  const route = (e, rt) => {
    e.preventDefault();
    e.stopPropagation();
    history(`/events/${event._id}/${rt}`);
  };
  return (
    <Fragment>
      <li>
        <button
          onClick={() => console.log("clicked")}
          className="dropdown-item"
          type="button"
        >
          Share
        </button>
      </li>
      {(event?.createdBy?._id === user?._id ||
        event?.Access.includes(user?.email) ||
        user?.is_siteadmin) && (
        <li>
          <button
            onClick={(e) => route(e, "scanner")}
            className="dropdown-item"
            type="button"
          >
            Scanner
          </button>
        </li>
      )}

      {(event?.createdBy?._id === user?._id || user?.is_siteadmin) && (
        <Fragment>
          <li>
            <button
              onClick={(e) => route(e, "update")}
              className="dropdown-item"
              type="button"
            >
              Edit
            </button>
          </li>
          <li>
            <button
              onClick={(e) => route(e, "manage")}
              className="dropdown-item"
              type="button"
            >
              Manage Event
            </button>
          </li>
          <li>
            <button
              onClick={(e) => dispatch({ type: "TOGGLE_STATS" })}
              className="dropdown-item"
              type="button"
            >
              {showStat ? "Hide Statistics" : "Show Statistics"}
            </button>
          </li>
        </Fragment>
      )}
    </Fragment>
  );
};

export default EventSelector;
