import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import api from "../../utils/api";
import { useDispatch, useSelector } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./EventForm/CheckoutForm";
import "./EventCard.css";
import DummyImage from "../../img/dummyEvent.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import EventStats from "./EventStats";
import TextField from "@mui/material/TextField";
import EventSelector from "./EventSelector";
import { setAlert } from "../../actions/alert";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
const EventCard = ({
  event,
  showForms,
  preview,
  from,
  route,
  onDeleteClick = (id) => {},
}) => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const { id } = useParams();
  const { user } = useSelector((state) => state.auth);
  const { showStat } = useSelector((state) => state.event);
  const [stats, setStats] = useState(null);
  const [activeIdx, setActiveIdx] = useState(0);
  const [showMenu, setShowMenu] = useState(false);
  const [errorMsg, setErrorMsg] = useState([]);
  const [isSubmmiting, setIsSubmitting] = useState(false);
  const [reservation, setReservation] = useState();
  const [code, setCode] = useState("");
  const [userDatails, setUserDetails] = useState({
    first_name: "",
    last_name: "",
    email: "",
    quantity: 1,
  });

  useEffect(() => {
    if (user)
      setUserDetails({
        name: user.name,
        email: user.email,
        first_name: user.first_name,
        last_name: user.last_name,
        quantity: 1,
      });
  }, [user]);
  useEffect(() => {
    if (id) {
      api
        .get(`/events/${id}/stats`)
        .then(({ data }) => {
          setStats(data);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [id]);

  const handleDeleteClick = () => {
    onDeleteClick(event._id);
  };

  const handleCodeVerification = async (e) => {
    e.preventDefault();
    setErrorMsg([]);

    try {
      const { data } = await api.get(`/events/${event._id}/verify`, {
        params: {
          code,
        },
      });
      if (data.validCode) {
        setCode("");
        setActiveIdx(1);
      }
    } catch (err) {
      if (err.response) dispatch(setAlert(err.response.data?.message, "error"));
      else dispatch(setAlert("Something went wrong", "error"));
    }
  };

  const handleUserDetailsSubmit = async (e) => {
    e.preventDefault();
    if (isSubmmiting) return;
    setIsSubmitting(true);
    const reqBody = {
      ...userDatails,
      eventId: event._id,
    };
    try {
      const { data } = await api.post("/reserve", reqBody);
      setReservation(data);
      setIsSubmitting(false);
      setActiveIdx(2);
    } catch (error) {
      if (error.response && error.response.data)
        dispatch(setAlert(error.response.data?.message, "error"));
      else dispatch(setAlert("Failed to verify user details", "error"));
      setIsSubmitting(false);
    }
  };
  if (!event) return null;
  const hourDiff =
    moment.duration(new Date(event?.eventStartDateAndTime)).asHours() -
    moment.duration(new Date()).asHours();

  const routeToProfile = () => {
    if (from !== "list") {
      history(`/u/${event?.createdBy?.display_name.replaceAll(" ", "")}`);
    }
  };
  console.log(route);
  return (
    <Fragment>
      {isSubmmiting ? (
        <div className="alert alert-info">Please wait</div>
      ) : null}
      <section
        onClick={(e) => {
          if (!showForms) history(`/events/${event._id}`);
        }}
      >
        <div className="events-event events-post-item">
          <div className="d-flex flex-column gap-3 p-3 border my-4">
            <div className="row gap-3">
              <div className="col-12 col-md-4 event-image">
                <div className="w-100d-block" style={{ height: 158 }}>
                  <Swiper
                    spaceBetween={30}
                    pagination={{
                      clickable: true,
                    }}
                    className="mySwiper"
                  >
                    {event.mediaList.length > 0 ? (
                      event.mediaList.map((res) => {
                        return (
                          <SwiperSlide key={res}>
                            <img
                              src={res}
                              alt="event imgs"
                              className="w-100 h-100 d-block"
                            />
                          </SwiperSlide>
                        );
                      })
                    ) : (
                      <SwiperSlide>
                        <img src={DummyImage} alt="dummy" />
                      </SwiperSlide>
                    )}
                  </Swiper>
                </div>
              </div>
              <div className="col-12 col-md-8 row gap-2">
                <div className="event-title-organized">
                  <div className="event-title">{event?.eventName}</div>
                  <div className="d-flex flex-column">
                    {route === "details" && (
                      <div
                        className={`event-organized-by d-flex mt-2`}
                        onClick={(e) => routeToProfile(e)}
                      >
                        <span style={{ marginRight: 5 }}>By</span>
                        <span
                          className={`${
                            from !== "list" && `pointer-cursor colorTeal`
                          }`}
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: event?.createdBy?.display_name.replace(
                                /-/g,
                                "&#8209;"
                              ),
                            }}
                          />
                        </span>
                      </div>
                    )}
                    <div
                      className="event-dis event-item-r"
                      style={{
                        color: "red",
                        fontWeight: 600,
                        padding: 0,
                        marginTop: 5,
                        marginBottom: 5,
                        marginLeft: 0,
                      }}
                    >
                      {event.published ? " " : "Unpublished Event"}{" "}
                      {event.isRecurring ? "Recurring Event" : ""}
                    </div>
                  </div>
                </div>
                <div className="event-date-time-row event-item-row">
                  <div className="icons-item">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path d="M160 32V64H288V32C288 14.33 302.3 0 320 0C337.7 0 352 14.33 352 32V64H400C426.5 64 448 85.49 448 112V160H0V112C0 85.49 21.49 64 48 64H96V32C96 14.33 110.3 0 128 0C145.7 0 160 14.33 160 32zM0 192H448V464C448 490.5 426.5 512 400 512H48C21.49 512 0 490.5 0 464V192zM64 304C64 312.8 71.16 320 80 320H112C120.8 320 128 312.8 128 304V272C128 263.2 120.8 256 112 256H80C71.16 256 64 263.2 64 272V304zM192 304C192 312.8 199.2 320 208 320H240C248.8 320 256 312.8 256 304V272C256 263.2 248.8 256 240 256H208C199.2 256 192 263.2 192 272V304zM336 256C327.2 256 320 263.2 320 272V304C320 312.8 327.2 320 336 320H368C376.8 320 384 312.8 384 304V272C384 263.2 376.8 256 368 256H336zM64 432C64 440.8 71.16 448 80 448H112C120.8 448 128 440.8 128 432V400C128 391.2 120.8 384 112 384H80C71.16 384 64 391.2 64 400V432zM208 384C199.2 384 192 391.2 192 400V432C192 440.8 199.2 448 208 448H240C248.8 448 256 440.8 256 432V400C256 391.2 248.8 384 240 384H208zM320 432C320 440.8 327.2 448 336 448H368C376.8 448 384 440.8 384 432V400C384 391.2 376.8 384 368 384H336C327.2 384 320 391.2 320 400V432z" />
                    </svg>
                  </div>
                  <div className="date-time-outer event-item-r">
                    <span className="date">
                      {moment(event.eventStartDateAndTime).format(
                        "ddd, MMMM Do YYYY"
                      )}
                    </span>{" "}
                    <span className="time">
                      {moment(event.eventStartDateAndTime).format("LT")} -{" "}
                      {moment(event.eventStartDateAndTime)
                        .add(event.eventDuration, "hours")
                        .format("LT")}{" "}
                      ({event.eventDuration}hrs)
                    </span>
                  </div>
                </div>
                <div className="event-address-row event-item-row">
                  <div className="icons-item">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 384 512"
                    >
                      <path d="M168.3 499.2C116.1 435 0 279.4 0 192C0 85.96 85.96 0 192 0C298 0 384 85.96 384 192C384 279.4 267 435 215.7 499.2C203.4 514.5 180.6 514.5 168.3 499.2H168.3zM192 256C227.3 256 256 227.3 256 192C256 156.7 227.3 128 192 128C156.7 128 128 156.7 128 192C128 227.3 156.7 256 192 256z" />
                    </svg>
                  </div>
                  <div className="address-bar event-item-r">
                    {/* <div className="address-tl">
                      Address <span>SHOW MAP</span>
                    </div> */}
                    <div className="full-address">
                      {event.location}
                      {/* <span className="copy-address">COPY</span> */}
                    </div>
                  </div>
                </div>
                <div className="event-discripation-row event-item-row">
                  <div className="icons-item">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 384 512"
                    >
                      <path d="M256 0v128h128L256 0zM224 128L224 0H48C21.49 0 0 21.49 0 48v416C0 490.5 21.49 512 48 512h288c26.51 0 48-21.49 48-48V160h-127.1C238.3 160 224 145.7 224 128zM272 416h-160C103.2 416 96 408.8 96 400C96 391.2 103.2 384 112 384h160c8.836 0 16 7.162 16 16C288 408.8 280.8 416 272 416zM272 352h-160C103.2 352 96 344.8 96 336C96 327.2 103.2 320 112 320h160c8.836 0 16 7.162 16 16C288 344.8 280.8 352 272 352zM288 272C288 280.8 280.8 288 272 288h-160C103.2 288 96 280.8 96 272C96 263.2 103.2 256 112 256h160C280.8 256 288 263.2 288 272z" />
                    </svg>
                  </div>
                  <div className="event-dis event-item-r">
                    {event.description}
                  </div>
                </div>
              </div>
            </div>
            {showForms &&
              hourDiff > 0 &&
              (event?.reservationEnabled ||
                event?.salesChannels.length > 0) && (
                <div className="border event-item-bottom">
                  {activeIdx === 0 && (
                    <div>
                      <div className="row align-items-start">
                        {event?.salesChannels.length > 0 && (
                          <div className="col-12 col-md-4">
                            <div className="prepaid-row">
                              {event?.salesChannels.length > 0 && (
                                <div className="prepaid-title">
                                  Book Prepaid Tickets
                                </div>
                              )}
                              <div className="mt-3">
                                {event?.salesChannels?.map((el, idx) => (
                                  <a
                                    key={el.name + idx}
                                    href={el.link}
                                    className="m-1"
                                  >
                                    <a href={el.link}>{el.name}</a>
                                  </a>
                                ))}
                                {event?.salesChannels.length === 0 && (
                                  <section
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      textAlign: "center",
                                      fontWeight: 600,
                                      color: "#25a2b7",
                                      fontSize: 18,
                                    }}
                                  >
                                    <div>Reserve your spot now</div>
                                    <div>Pay when you attend</div>
                                  </section>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {event?.reservationEnabled && (
                          <div
                            className={
                              event?.salesChannels.length > 0
                                ? "col-12 col-md-8"
                                : "col-12 col-md-12"
                            }
                          >
                            <div className="reservation-row">
                              <div className="reservation-left">
                                <div className="reservation-title">
                                  Reservation
                                </div>
                                <div className="reservation-benefits">
                                  Benefits:{" "}
                                  <span>
                                    {Array.isArray(event?.Reservation?.benefits)
                                      ? event?.Reservation?.benefits?.map(
                                          (benefit, idx) => {
                                            return (
                                              <div key={"benefit" + idx}>
                                                {benefit}
                                              </div>
                                            );
                                          }
                                        )
                                      : event?.Reservation?.benefits}
                                  </span>
                                </div>
                              </div>
                              <div className="reservation-right">
                                <div className="reservation-price">
                                  Price: ${event?.Reservation?.price}{" "}
                                  <span>(Pay at the event)</span>
                                </div>
                                <form
                                  className="d-flex flex-column flex-md-row gap-2"
                                  onSubmit={handleCodeVerification}
                                >
                                  {event?.Reservation?.code?.length > 5 && (
                                    <div className="EnterCode">
                                      <TextField
                                        type="text"
                                        focused
                                        label="Enter code"
                                        className="form-control"
                                        color="success"
                                        value={code}
                                        onChange={(e) =>
                                          setCode(e.target.value)
                                        }
                                        required
                                        inputProps={{
                                          maxLength: 6,
                                          minLength: 6,
                                        }}
                                      />
                                    </div>
                                  )}
                                  <div className="d-flex align-items-center">
                                    <button type="submit" className="btn m-0">
                                      Reserve
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}
            <div style={{ pointerEvents: preview ? "none" : "auto" }}>
              {activeIdx === 1 && (
                <div>
                  <form
                    className="row align-items-center gap-3"
                    onSubmit={handleUserDetailsSubmit}
                  >
                    <div className="col d-flex flex-row gap-3 WrapCol">
                      <TextField
                        type="text"
                        label="First Name"
                        className="form-control"
                        value={userDatails.first_name}
                        onChange={(e) =>
                          setUserDetails((prev) => ({
                            ...prev,
                            first_name: e.target.value,
                          }))
                        }
                        required
                      />
                      <TextField
                        type="text"
                        label="Last Name"
                        className="form-control"
                        value={userDatails.last_name}
                        onChange={(e) =>
                          setUserDetails((prev) => ({
                            ...prev,
                            last_name: e.target.value,
                          }))
                        }
                        required
                      />

                      <TextField
                        type="email"
                        label="email"
                        className="form-control"
                        value={userDatails.email}
                        onChange={(e) =>
                          setUserDetails((prev) => ({
                            ...prev,
                            email: e.target.value,
                          }))
                        }
                        required
                      />
                      <TextField
                        type="number"
                        label="Quantity"
                        className="form-control"
                        InputProps={{
                          inputProps: {
                            min: 1,
                          },
                        }}
                        value={userDatails.quantity}
                        onChange={(e) =>
                          setUserDetails((prev) => ({
                            ...prev,
                            quantity: e.target.value,
                          }))
                        }
                        required
                      />
                    </div>
                    <div className="col-12 col-md-4 d-flex">
                      <button className="btn">Next</button>
                    </div>
                  </form>
                </div>
              )}

              {activeIdx === 2 ? (
                <Elements stripe={stripePromise} options={{ loader: "auto" }}>
                  <CheckoutForm
                    reservation={reservation}
                    event={event}
                    triggerOnTimeout={setActiveIdx}
                  />
                </Elements>
              ) : null}
            </div>
          </div>
          <div
            onMouseEnter={() => setShowMenu(true)}
            onMouseLeave={() => setShowMenu(false)}
          >
            <div className="events-event-action border drop-toggle-icon">
              <div className="dropdown" style={{ zIndex: 2 }}>
                <span onClick={() => setShowMenu(true)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                    fill="#888"
                  >
                    <path d="M224 416c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L224 338.8l169.4-169.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-192 192C240.4 412.9 232.2 416 224 416z" />
                  </svg>
                </span>
                <ul
                  className={showMenu ? " dropdown-menu show" : "dropdown-menu"}
                  aria-labelledby="dropdownMenu2"
                >
                  <EventSelector event={event} />
                </ul>
              </div>
            </div>
          </div>
          {showForms && showStat && <EventStats stats={stats} />}
        </div>
      </section>
    </Fragment>
  );
};

export default EventCard;
