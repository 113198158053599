import { Button, TextField } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import BgImage from "../../img/showcase.jpg";
import api from "../../utils/api";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../actions/alert";
import history from "../../utils/history";

const Landing = () => {
  const Auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [load, setload] = useState(false);
  const [email, setemail] = useState("");
  useEffect(() => {
    if (localStorage.getItem("Load") === null) {
      setload(true);
      localStorage.setItem("Load", true);
    }
  }, []);

  const SubmitEmail = () => {
    api
      .post(`http://206.189.144.23:2017/registerEmail`, { email })
      .then(({ data }) => {
        dispatch(setAlert(data?.message, "success"));
      })
      .catch((err) => {
        dispatch(setAlert(err?.message, "error"));
      });
  };

  useEffect(() => {
    if (Auth.isAuthenticated) {
      history.push("/dashboard");
    }
  }, []);
  return (
    <Fragment>
      <div
        style={{
          backgroundImage: `url(${BgImage})`,
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          backgroundSize: "cover",
          height: "100vh",
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          backgroundPositionX: "20%",
          backgroundPositionY: "50%",
          backgroundColor: "#363636",

          // transform: localStorage.getItem("Load")
          //   ? "scale(1)"
          //   : load
          //   ? "scale(1)"
          //   : "rotate(2deg) scale(1.05) ",
          // transitionDuration: load && "10s",
          // transitionDelay: load && "1s",
        }}
      >
        <div
          className="dark-overlay"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div>
            <div
              style={{
                marginBottom: 10,
                color: "#eee",
                fontSize: 22,
                fontFamily: "Trebuchet MS, Arial, Helvetica, sans-serif",
              }}
              className="dashText"
            >
              Music,&nbsp;Dance&nbsp;&amp;&nbsp;more&nbsp;...
            </div>

            <div
              style={{
                marginBottom: 10,
                color: "#eee",
                fontSize: 15,
                fontFamily: "Trebuchet MS, Arial, Helvetica, sans-serif",
              }}
              className="dashText"
            >
              Get notified when we launch near you
            </div>
            <div className="d-flex flex-wrap dashBtnCnt">
              <input
                className="dashInput"
                variant="outlined"
                label="Email"
                placeholder="Your Email"
                value={email}
                onChange={(e) => setemail(e.target.value)}
              />{" "}
              <div className="d-flex align-items-center">
                <Button
                  variant="contained"
                  className="joinBtn"
                  onClick={() => SubmitEmail()}
                >
                  Join
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Landing;
