import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../utils/api";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import "./style.css";
import { Button, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../actions/alert";
import Countdown from "react-countdown";
import Counter from "./Counter";

const ViewPoll = () => {
  const dispatch = useDispatch();
  const [poll, setPoll] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [email, setemail] = useState(null);
  const id = window.location.pathname.split("/")[2];
  const [Selected, setSelected] = useState([]);
  const [Submitted, setSubmitted] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const [TimeRemian, setTimeRemian] = useState(null);

  useEffect(() => {
    const getPoll = async () => {
      try {
        const res = await api.get(`/poll/${id}`);
        setPoll(res?.data.poll);
        setTimeRemian(res?.data?.timeRemaining);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      } catch (err) {
        setError("Failed to fetch poll");
        setLoading(false);
      }
    };
    getPoll();
  }, [Submitted]);

  const handleChange = (event) => {
    if (event?.target?.checked) {
      setSelected([...Selected, event.target.name]);
    } else {
      setSelected(Selected.filter((item) => item !== event?.target?.name));
    }
  };

  const Submit = async () => {
    api
      .patch(`/poll/${id}/vote`, {
        options: Selected,
        email,
      })
      .then((res) => {
        setSubmitted(true);
        dispatch(setAlert("Thank You", "success"));
      })
      .catch((err) => {
        dispatch(setAlert(err?.response?.data?.message, "error"));
      });
  };
  useEffect(() => {
    if (TimeRemian && poll?.pollStatus) {
      setTimeout(() => {
        setPoll({ ...poll, pollStatus: false });
      }, TimeRemian);
    }
  }, [TimeRemian]);

  return (
    <div>
      {loading && <div>Loading...</div>}
      {error && <div>{error}</div>}
      <div className="pollCouter">
        <div>Poll will close in :</div>
        {poll?.pollStatus && <Counter TimeRemian={TimeRemian} />}
        {!poll?.pollStatus && <span>Finished</span>}
      </div>
      {!user?.cmtyUserId && !loading && (
        <section className="p10">
          <TextField
            label="Enter your email"
            name="email"
            variant="outlined"
            className="form-control"
            fullWidth
            value={email}
            required
            onChange={(e) => setemail(e.target.value)}
          />
        </section>
      )}
      {poll && !loading && (
        <section>
          <div className="pollCard">
            <div>
              <h5>{poll?.question}</h5>
              {poll?.options?.map((option, index) => {
                return (
                  <div key={option._id}>
                    <FormControlLabel
                      name={option._id}
                      onChange={(e) => handleChange(e)}
                      value={option.option}
                      control={<Checkbox />}
                      label={option.option}
                      labelPlacement="end"
                    />
                    <span className="voteCount">{option.votes} Votes</span>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      )}
      {Selected?.length > 0 &&
        !Submitted &&
        (user?.cmtyUserId || email?.length > 5) && (
          <section className="p10 d-end">
            <Button
              disabled={poll.pollStatus ? false : true}
              className="pb-pl-btn"
              variant="contained"
              onClick={() => Submit()}
            >
              {poll.pollStatus ? "Submit Poll" : "Poll Closed"}
            </Button>
          </section>
        )}
    </div>
  );
};

export default ViewPoll;
