import React, { useState } from "react";
import { Link } from "react-router-dom";
import { CopyOutlined, EyeOutlined, SettingOutlined } from "@ant-design/icons";
import { notification } from "antd";
import { useNavigate } from "react-router-dom";

const Website = (data) => {
  const history = useNavigate();
  const { _id, name, description, city, status, widgetToken } = data.website;
  const [linkToggle, setlinkToggle] = useState(false);
  const [linkToggleJSX, setlinkToggleJSX] = useState(false);
  return (
    <section>
      <div className="my-reservations-outer">
        <div className="reservatios-item">
          <div className="reserve-title-btns-row">
            <div className="reserve-title-o">
              <div className="for-by">{name}</div>
            </div>

            <div className="reserve-btn-o">
              <Link to={`/website/${_id}`}>Edit</Link>
            </div>
          </div>
          <div className="mt-2">{description}</div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 10,
            }}
          >
            <div>{city}</div>
            <div
              style={{
                fontWeight: "bold",
              }}
            >
              {status.charAt(0).toUpperCase() + status.slice(1)}
            </div>
          </div>
          <section className="event-name-time">
            {" "}
            <section
              className="d-flex justify-content-center cursor-pointer"
              onClick={() => {
                history(`/website/${_id}/ads`);
              }}
            >
              Ads Config <SettingOutlined />
            </section>
          </section>

          <div className="event-name-time">
            <section className="d-flex">
              {" "}
              <div>Website Widget (HTML)</div>
              <CopyOutlined
                className="cursor-pointer"
                onClick={() => {
                  navigator.clipboard.writeText(
                    `<script       
      defer="defer"
      ver="0.7.3"
      key="juznowWebsiteWidget"
      src=${process?.env?.REACT_APP_WIDGET_CDN_URL}
      token=${widgetToken}
      >
      
      </script>`
                  );
                  notification.success({
                    message: "Event Widget copied for " + name,
                  });
                }}
              />{" "}
              <EyeOutlined
                className="cursor-pointer"
                onClick={() => {
                  setlinkToggle(!linkToggle);
                }}
              />
            </section>
          </div>
          <div>
            {linkToggle && (
              <div
                style={{
                  fontSize: 10,
                  border: "1px solid #000",
                  padding: 10,
                  wordBreak: "break-all",
                  backgroundColor: "#000000ab",
                  color: "white",
                }}
              >
                {`<script
                  defer="defer"
                  ver="0.7.3"
                  key="juznowWebsiteWidget"
                  src=${process.env.REACT_APP_WIDGET_CDN_URL}
                  token=${widgetToken}
                ></script>`}
              </div>
            )}
          </div>
          <div className="event-name-time">
            <section className="d-flex">
              {" "}
              <div>Website Widget (JSX)</div>
              <CopyOutlined
                className="cursor-pointer"
                onClick={() => {
                  navigator.clipboard.writeText(
                    `  useEffect(() => {
    const existingIframe = document.getElementById("juznow-web-widget");
    if (existingIframe) {
      return;
    }
    const script = document.createElement("script");
    script.src ="${process.env.REACT_APP_WIDGET_LOADER_CDN_URL}";
    script.defer = true;
    script.ver="0.7.3"
    script.type = "text/javascript";
    script.setAttribute(
      "token",
      "${widgetToken}"
    );
    script.setAttribute("height", "95vh");
    script.setAttribute("width", "100vw");
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
      const e = document.getElementById("juznow-web-widget");
      if (e) {
        document.body.removeChild(e);
      }
    };
  }, []);`
                  );
                  notification.success({
                    message: "Event Widget copied for " + name,
                  });
                }}
              />{" "}
              <EyeOutlined
                className="cursor-pointer"
                onClick={() => {
                  setlinkToggleJSX(!linkToggleJSX);
                }}
              />
            </section>
          </div>
          <div>
            {linkToggleJSX && (
              <div
                style={{
                  fontSize: 10,
                  border: "1px solid #000",
                  padding: 10,
                  wordBreak: "break-all",
                  backgroundColor: "#000000ab",
                  color: "white",
                }}
              >
                {`  useEffect(() => {
    const existingIframe = document.getElementById("juznow-web-widget");
    if (existingIframe) {
      return;
    }
    const script = document.createElement("script");
    script.src ="${process.env.REACT_APP_WIDGET_LOADER_CDN_URL}";
    script.ver="0.7.3"
    script.defer = true;
    script.type = "text/javascript";
    script.setAttribute(
      "token",
      "${widgetToken}"
    );
    script.setAttribute("height", "95vh");
    script.setAttribute("width", "100vw");
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
      const e = document.getElementById("juznow-web-widget");
      if (e) {
        document.body.removeChild(e);
      }
    };
  }, []);`}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Website;
