import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { NavLink, Navigate, useNavigate, useParams } from "react-router-dom";
import EventPreview from "../EventPreview";
import api from "../../../utils/api";
import Dialog from "../../Dialog";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../../actions/alert";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
const defaultValues = {
  link: "",
  name: "",
};
const AddPrepaidChannelsForm = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const { register, handleSubmit, reset, getValues } = useForm({
    defaultValues,
  });
  const [vendors, setVendors] = useState([]);
  const [showPreview, setShowPreview] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [submitting, setSubmmiting] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  const [eventData, setEventData] = useState({
    loading: true,
    errorMsg: null,
    event: null,
  });

  useEffect(() => {
    setEventData((prev) => ({ ...prev, loading: true, errorMsg: null }));
    api(`/events/${params.id}/event-with-auth`)
      .then(({ data }) => {
        setEventData((prev) => ({ ...prev, event: data, loading: false }));
        setVendors(data.salesChannels);
      })
      .catch((err) => {
        setEventData((prev) => ({
          ...prev,
          event: null,
          loading: false,
          errorMsg: "Event not found",
        }));
      });
  }, [params.id, submitting]);

  const handleAddLink = async (data) => {
    if (vendors.length >= 3) {
      alert("max 3 third party ticket providers can be added");
      return;
    }
    if (submitting) return;
    setSubmmiting(true);
    setErrorMsg(null);

    const vendorsData = {
      salesChannels: [...vendors, data],
    };

    try {
      await api.patch(`/events/${params.id}`, vendorsData);
      setSubmmiting(false);
      setVendors((prev) => [...prev, data]);
      reset();
    } catch (err) {
      setSubmmiting(false);
      setErrorMsg("Failed to add provider");
    }
  };
  const handleRemoveLink = async (data) => {
    let temp = vendors.filter((v) => v !== data);
    const vendorsData = {
      salesChannels: temp,
    };
    setVendors(temp);
    try {
      await api.patch(`/events/${params.id}`, vendorsData);
      setSubmmiting(false);
      reset();
    } catch (err) {
      setSubmmiting(false);
      setErrorMsg("Failed to add provider");
    }
  };

  const handleNextClick = async () => {
    const formData = getValues();
    if (formData.link.length > 0 && formData.name.length > 0) {
      handleAddLink(formData);
    }
    if (submitting) return;
    setSubmmiting(true);
    setErrorMsg(null);

    try {
      await api.patch(`/events/${params.id}/publish`);
      setSubmmiting(false);
      setShowSuccessModal(true);
    } catch (err) {
      setSubmmiting(false);
      setErrorMsg("Failed to publish");
    }
  };

  if (eventData.loading) return <h1>Loading...</h1>;
  if (eventData.errorMsg) return <h1>{eventData.errorMsg}</h1>;
  if (eventData?.event?.createdBy?._id !== user?._id || user?.is_siteadmin)
    return <Navigate to={"/events"} />;
  const SendInvite = () => {
    let obj = {
      eventId: eventData.event._id,
      pollId: eventData.event?.pollId,
      pollOpId: eventData.event.pollOpId,
    };
    api
      .post("/events/invite", obj)
      .then(({ data }) => {
        dispatch(setAlert(data.status, "success"));
        api(`/events/${params.id}/event-with-auth`)
          .then(({ data }) => {
            setEventData((prev) => ({ ...prev, event: data, loading: false }));
            setVendors(data.salesChannels);
          })
          .catch((err) => {
            setEventData((prev) => ({
              ...prev,
              event: null,
              loading: false,
              errorMsg: "Event not found",
            }));
          });
      })
      .catch(({ response }) => {
        dispatch(setAlert(response?.data?.message, "error"));
      });
  };

  console.log(eventData, eventData?.event.isRecurring);
  return (
    <div className="px-3 py-5">
      {submitting ? (
        <div className="alert alert-info">
          Please wait while event is being updated
        </div>
      ) : null}
      {errorMsg ? <div className="alert alert-danger">{errorMsg}</div> : null}
      <div>
        <h1 className="mb-2">Add Prepaid Channels</h1>
      </div>

      <div className="d-flex flex-column gap-3">
        <form
          className="d-flex gap-3 flex-wrap"
          onSubmit={handleSubmit(handleAddLink)}
        >
          <div className="form-group">
            <TextField
              variant="outlined"
              type="url"
              label="link"
              className="form-control"
              name="link"
              {...register("link")}
              required
            />
          </div>
          <div className="form-group">
            <TextField
              variant="outlined"
              type="text"
              label="name"
              className="form-control"
              name="name"
              {...register("name")}
              required
            />
          </div>
          <button type="submit" className="btn">
            +
          </button>
        </form>

        {vendors?.length ? (
          <div className="d-flex flex-wrap gap-3">
            {vendors.map((vendor) => (
              <Fragment>
                <a
                  key={vendor.name}
                  href={vendor.link}
                  target="_blank"
                  rel="noreferrer"
                >
                  {vendor.name}
                </a>
                <span
                  onClick={() => handleRemoveLink(vendor)}
                  className="pointer-cursor"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-trash"
                    viewBox="0 0 16 16"
                  >
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                    <path
                      fill-rule="evenodd"
                      d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                    />
                  </svg>
                </span>
              </Fragment>
            ))}
          </div>
        ) : null}
      </div>

      <div className="mt-5 d-flex justify-content-between gap-3">
        <NavLink
          to={`/events/${params.id}/add-reservation`}
          replace
          className="btn"
        >
          Go back
        </NavLink>
        <div className="d-flex gap-3">
          <button
            type="btn"
            className="btn"
            onClick={() => setShowPreview(true)}
          >
            Preview
          </button>
          <button className="btn" onClick={handleNextClick}>
            Next
          </button>
        </div>
      </div>

      <EventPreview
        open={showPreview}
        onClose={() => setShowPreview(false)}
        event={{ ...eventData.event, salesChannels: vendors }}
      />

      <Dialog
        open={showSuccessModal}
        onClose={() => {
          setShowSuccessModal(false);
          history("/events");
        }}
      >
        <div
          className="d-flex flex-column gap-3 align-items-center justify-content-center"
          style={{ overflow: "hidden" }}
        >
          <big>Your event has been created</big>

          {!eventData?.event.isRecurring && (
            <div className="d-flex gap-3 align-items-center flex-wrap flex-column">
              <a
                style={{ wordBreak: "break-all" }}
                href={`${window.location.protocol}//${window.location.host}/events/${params.id}`}
                target="_blank"
                rel="noreferrer"
              >
                {`${window.location.protocol}//${window.location.host}/events/${params.id}`}{" "}
              </a>
              <Button
                variant="outlined"
                onClick={async () => {
                  const url = `${window.location.protocol}//${window.location.host}/events/${params.id}`;
                  window.navigator.clipboard.writeText(url).then(() => {
                    dispatch(setAlert("Address copied", "success"));
                  });
                }}
              >
                Copy Event Link
              </Button>
              {/* <span className="btn">Copy</span> */}
            </div>
          )}

          <p>
            <p>
              Reservation Code:{" "}
              <span style={{ fontWeight: 600 }}>
                {eventData.event?.Reservation?.code
                  ? eventData.event?.Reservation?.code
                  : "Not Required"}
              </span>
            </p>
          </p>
          {eventData?.event?.pollId && (
            <Fragment>
              {eventData?.event?.invite && (
                <Fragment>
                  <div>
                    Do you want to send an invite for this event to users who
                    voted for this option ?
                  </div>
                  <div>or</div>
                  <div>You can send it later, from manage event section.</div>
                </Fragment>
              )}

              <Button
                onClick={() => SendInvite()}
                className={
                  eventData?.event?.invite && eventData?.event?.pollId
                    ? "send-invite"
                    : "send-invite-false"
                }
              >
                {eventData?.event?.invite && eventData?.event?.pollId
                  ? ` Send email invites`
                  : `Email invite sent`}
              </Button>
            </Fragment>
          )}
          <div>
            <NavLink
              to="/events"
              replace
              onClick={() => setShowSuccessModal(false)}
              className="btn btn-success"
            >
              Done
            </NavLink>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default AddPrepaidChannelsForm;
