import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../utils/api";
import Spinner from "../layout/Spinner";
import EventCard from "./EventCard";

export default function EventDetails() {
  const params = useParams();
  const history = useNavigate();

  const [eventData, setEventData] = useState({
    loading: false,
    errorMsg: null,
    event: null,
  });

  useEffect(() => {
    setEventData((prev) => ({ ...prev, loading: true, errorMsg: null }));

    api(`/events/${params.id}`)
      .then(({ data }) => {
        setEventData((prev) => ({ ...prev, event: data, loading: false }));
      })
      .catch((err) => {
        setEventData((prev) => ({
          ...prev,
          event: null,
          loading: false,
          errorMsg: "Event not found",
        }));
      });
  }, [params.id]);

  const onDeleteClick = async (id) => {
    try {
      await api.delete(`/events/${id}`);
      history("/events");
    } catch (err) {}
  };

  if (eventData.loading) return <Spinner />;
  if (eventData.errorMsg) return <h1>{eventData.errorMsg}</h1>;

  return (
    <div>
      <EventCard
        showForms={true}
        event={eventData.event}
        onDeleteClick={onDeleteClick}
        route="details"
      />
    </div>
  );
}
