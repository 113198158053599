import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

const AlertType = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Alert = ({ alerts }) => (
  <Stack spacing={8} sx={{ width: "100%" }}>
    {alerts.map((alert) => {
      return (
        <Snackbar
          open={alert.id ? true : false}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <AlertType sx={{ width: "100%", mt: 8 }} severity={alert.alertType}>
            {alert.msg}
          </AlertType>
        </Snackbar>
      );
    })}
  </Stack>
);

Alert.propTypes = {
  alerts: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  alerts: state.alert,
});

export default connect(mapStateToProps)(Alert);
