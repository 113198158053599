import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import PostItem from "./PostItem";
import PostForm from "./PostForm";
import { getPosts } from "../../actions/post";
import { NavLink } from "react-router-dom";

const Posts = ({ getPosts, post: { posts } }) => {
  useEffect(() => {
    getPosts();
  }, [getPosts]);

  return (
    <div>
      <div className="d-flex my-2 align-items-center">
        <div>
          <NavLink
            to={"/posts"}
            className="btn"
            activeClassName="btn-primary"
            style={{ backgroundColor: "#17a2b8", color: "white" }}
          >
            Post
          </NavLink>
        </div>
        <div>
          <NavLink
            to={"/events/create"}
            className="btn"
            activeClassName="btn-primary"
          >
            Event
          </NavLink>
        </div>
      </div>
      <h1 className="large text-primary">Add a Post</h1>
      <PostForm />
      <div className="posts">
        {posts.map((post) => (
          <PostItem key={post._id} post={post} />
        ))}
      </div>
    </div>
  );
};

Posts.propTypes = {
  getPosts: PropTypes.func.isRequired,
  post: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  post: state.post,
});

export default connect(mapStateToProps, { getPosts })(Posts);
