import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { NavLink, Navigate, useNavigate, useParams } from "react-router-dom";
import Dialog from "../../Dialog";
import EventPreview from "../EventPreview";
import "../styles/vip-reservations.scss";
import api from "../../../utils/api";
import { useSelector } from "react-redux";
import TextField from "@mui/material/TextField";

const defaultValues = {
  inventory: "",
  price: "",
  code: "",
  benefits: "",
};
const VipReservations = () => {
  const history = useNavigate();
  const params = useParams();
  const { user } = useSelector((state) => state.auth);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [submitting, setSubmmiting] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [reservationData, setreservationData] = useState(null);
  const [MaxLimit, setMaxLimit] = useState([]);
  const [eventData, setEventData] = useState({
    loading: true,
    errorMsg: null,
    event: null,
  });

  const { register, handleSubmit, setValue, getValues } = useForm({
    defaultValues,
  });

  useEffect(() => {
    setEventData((prev) => ({ ...prev, loading: true, errorMsg: null }));
    // here put method is used to fetch not to update
    api
      .get(`/events/${params.id}/event-with-auth`)
      .then(({ data }) => {
        setEventData((prev) => ({ ...prev, event: data, loading: false }));
        setMaxLimit(data?.attendeeLimit ?? 30);
        if (data.Reservation) {
          const Reservation = data.Reservation;
          setreservationData(Reservation);
          setValue("inventory", Reservation?.inventory ?? "");
          setValue("price", Reservation?.price ?? "");
          setValue("code", Reservation?.code ?? "");
          setValue("benefits", Reservation?.benefits.toString() ?? "");
        }
      })
      .catch((err) => {
        setEventData((prev) => ({
          ...prev,
          event: null,
          loading: false,
          errorMsg: "Event not found",
        }));
      });
  }, [params.id, history, setValue]);

  const handleFormSubmit = async (data) => {
    if (submitting) return;
    setSubmmiting(true);
    setErrorMsg(null);
    const vipData = {
      Reservation: data,
    };
    try {
      await api.patch(`/events/${params.id}`, vipData);
      setSubmmiting(false);
      history(`/events/${params.id}/add-third-party-ticket-provider`);
    } catch (err) {
      setSubmmiting(false);
      setErrorMsg("Failed to update event");
    }
  };

  if (eventData.loading) return <h1>Loading...</h1>;
  if (eventData.errorMsg) return <h1>{eventData.errorMsg}</h1>;
  if (eventData?.event?.createdBy?._id !== user?._id || user?.is_siteadmin)
    return <Navigate to={"/events"} />;
  console.log("eventData=====>", eventData?.event?.reservationEnabled);
  return (
    <div className="vip px-2 py-5">
      {submitting ? (
        <div className="alert alert-info">
          Please wait while event is being updated
        </div>
      ) : null}
      {errorMsg ? <div className="alert alert-danger">{errorMsg}</div> : null}
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            marginTop: "20px",
          }}
        >
          <h1>Add Reservation</h1>
          <div onClick={() => setShowInfoModal(true)} className="vip-info">
            <span>?</span>
          </div>
        </div>
        <p>
          <em></em>
        </p>
      </div>

      <div>
        <form
          className="d-flex flex-column gap-3"
          onSubmit={handleSubmit(handleFormSubmit)}
        >
          {
            <section
              className={
                eventData?.event?.enableInventoryUi ? "" : "grayed-out"
              }
            >
              {" "}
              <div className="d-flex gap-3 from-group flex-wrap">
                <TextField
                  variant="outlined"
                  type="number"
                  label="Add Reservations"
                  className="form-control"
                  {...register("inventory")}
                  required={eventData?.event?.enableInventoryUi}
                  InputProps={{
                    inputProps: {
                      max: MaxLimit,
                      min:
                        reservationData?.inventory -
                          reservationData?.inventoryRemaining >
                        4
                          ? reservationData?.inventory -
                            reservationData?.inventoryRemaining
                          : 4,
                    },
                  }}
                />
                <TextField
                  type="number"
                  variant="outlined"
                  label="Price"
                  className="form-control"
                  {...register("price")}
                  required={eventData?.event?.enableInventoryUi}
                  InputProps={{
                    inputProps: {
                      min: 0,
                    },
                  }}
                />
                <TextField
                  type="text"
                  label="Code"
                  variant="outlined"
                  className="form-control"
                  {...register("code")}
                  inputProps={{ maxLength: 6, minLength: 6 }}
                />
              </div>
              <div className="form-group mt-3">
                <TextField
                  type="text"
                  variant="outlined"
                  label="Reservations Benefits"
                  className="form-control"
                  {...register("benefits")}
                />
              </div>
              {!eventData?.event?.enableInventoryUi && (
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent overlay
                    color: "white",
                    fontSize: "24px",
                    zIndex: 2, // Ensure it's above the content
                  }}
                >
                  Reservations Disabled
                </div>
              )}
            </section>
          }

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <NavLink to={`/events/${params.id}/update`} replace className="btn">
              Go back
            </NavLink>
            <div className="d-flex gap-3">
              <button
                type="button"
                className="btn"
                onClick={() => setShowPreviewModal(true)}
              >
                Preview
              </button>
              <button type="submit" className="btn">
                Next
              </button>
            </div>
          </div>
        </form>
      </div>

      <EventPreview
        open={showPreviewModal}
        onClose={() => setShowPreviewModal(false)}
        event={{
          ...eventData.event,
          Reservation: {
            inventory: getValues("inventory"),
            price: getValues("price"),
            code: getValues("code"),
            benefits: getValues("benefits"),
          },
        }}
      />

      <Dialog open={showInfoModal} onClose={() => setShowInfoModal(false)}>
        <div>
          <h1 className="text-center">About Reservations</h1>

          <p>
            This feature allows you to accept reservations on Juznow. The guest
            pays a small fee to reserve a number of spots at the event. The
            entry price is paid when the guest arrives at the event.
          </p>

          <p>
            The guest presents their reservation with a QR code that you or your
            staff can scan to display the amount they have to pay. This is
            typically the price provided in the price field. However, with
            discounts etc this may vary so it is important to scan each QR code
            to confirm the amount.
          </p>
        </div>
      </Dialog>
    </div>
  );
};
export default VipReservations;
