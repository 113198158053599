import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../utils/api";
import "./style.css";

const PollList = () => {
  const [polls, setPolls] = useState([]);
  const navigate = useNavigate();
  const getPolls = async () => {
    const res = await api.get("/poll");
    setPolls(res?.data);
  };
  useEffect(() => {
    getPolls();
  }, []);

  const EndPole = (e, id) => {
    api
      .patch(`/poll/${id}/endpoll`)
      .then((res) => {
        getPolls();
      })
      .catch((err) => {});
    e.stopPropagation();
  };

  const polnavigate = (id) => {
    navigate(`/poll/${id}`);
  };
  const eventByPoll = (e, pid, opid, ) => {
    navigate(`/events/create/poll/${pid}/option/${opid}`);
    e.stopPropagation();
  };

  const PollCard = ({ poll }) => {
    return (
      <div className="pollCard" onClick={() => polnavigate(poll?._id)}>
        {
          <div className="p-Section">
            <section>
              <Button
                disabled={poll.pollStatus ? false : true}
                className={
                  poll.pollStatus ? "pb-pl-btn-end" : "pb-pl-btn-end-fn"
                }
                variant="contained"
                onClick={(e) => EndPole(e, poll._id)}
              >
                {poll.pollStatus ? " End Poll" : "Poll Finished"}
              </Button>
            </section>
          </div>
        }
        <div>
          <h5>{poll?.question}</h5>
          {poll?.options?.map((option, index) => {
            return (
              <div key={option?._id}>
                <li className="mb-2">
                  {option?.option}
                  {" - "}
                  <span className="voteCount">{option?.votes} Votes</span>
                  {!poll.pollStatus && (
                    <span>
                      <Button
                        variant="outlined"
                        className="cr-ev"
                        onClick={(e) =>
                          eventByPoll(
                            e,
                            poll._id,
                            option?._id,
                          )
                        }
                      >
                        Create Event
                      </Button>
                    </span>
                  )}
                </li>
              </div>
            );
          })}
        </div>
      </div>
    );
  };
  return (
    <div>
      <section className="page-title-back-btn-outer">
        <div className="back-btn pointer-cursor" onClick={() => navigate(-1)}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
            <path d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z" />
          </svg>
          Back
        </div>
        <div className="page-title">My Polls</div>
      </section>
      <section>
        {polls &&
          polls?.map((poll) => {
            return (
              <div className="pointer-cursor">
                <PollCard poll={poll} />
              </div>
            );
          })}
      </section>
    </div>
  );
};

export default PollList;
