import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { Link, useParams } from "react-router-dom";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import ProfileTop from "./ProfileTop";
import { useDispatch } from "react-redux";

// import ProfileAbout from "./ProfileAbout";
// import ProfileExperience from "./ProfileExperience";
// import ProfileEducation from "./ProfileEducation";
// import ProfileGithub from "./ProfileGithub";
import { getProfileById } from "../../actions/profile";
import EventList from "../events/AllEvents";

const Profile = ({ profile: { profile }, auth }) => {
  const dispatch = useDispatch();
  const { user } = useParams();
  useEffect(() => {
    dispatch(getProfileById(user));
  }, [user]);

  return (
    <section>
      {profile !== null && (
        <Fragment>
          {/* <Link to="/profiles" className="btn btn-light">
            Back To Profiles
          </Link> */}
          {auth?.isAuthenticated &&
            auth?.loading === false &&
            auth?.user?.email === profile && (
              <Link to="/account-settings" className="btn btn-dark">
                Edit Profile
              </Link>
            )}
          <div className="my-1">
            <ProfileTop profile={profile} />
            <EventList route={"profile"} user={user} />
          </div>
        </Fragment>
      )}
    </section>
  );
};

Profile.propTypes = {
  profile: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  auth: state.auth,
});

export default connect(mapStateToProps, { getProfileById })(Profile);
