import api from "../utils/api";
import { setAlert } from "./alert";
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
} from "./types";

// Load User
export const loadUser = () => async (dispatch) => {
  try {
    const res = await api.get("/auth");
    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};
// Register User
export const register = (formData) => async (dispatch) => {
  try {
    const res = await api.post("/users", formData);
    dispatch({
      type: REGISTER_SUCCESS,
      payload: res.data,
    });
    dispatch(setAlert(res.data.msg, "success"));
    dispatch(loadUser());
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: REGISTER_FAIL,
    });
  }
};
// Login User
export const login = (email, password) => async (dispatch) => {
  const body = { email, password };

  try {
    const res = await api.post("/auth", body);

    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });

    dispatch(loadUser());
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
    }

    dispatch({
      type: LOGIN_FAIL,
    });
  }
};
// Logout
export const logout = () => ({ type: LOGOUT });
// reset
export const resetEmail = (data) => async (dispatch) => {
  try {
    const res = await api.post("/auth/reset-password", data);
    if (res.status === 200) {
    }
  } catch (err) {
    if (err) {
      err.forEach((error) => dispatch(setAlert(err.msg, "error")));
    }
  }
};
// Change
export const ChangePassword = (data) => async (dispatch) => {
  try {
    const res = await api.post("/auth/change-password", data);
    if (res.status === 200) {
      dispatch(setAlert(res.data.message, "success"));
    }
  } catch (err) {
    const errors = err.response.data.message;
    if (errors) {
      dispatch(setAlert(err.response.data.message, "error"));
    }
  }
};
