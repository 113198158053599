import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import api from "../../utils/api";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Button, Modal, Form, Input, DatePicker, Select, Empty } from "antd";
import { Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import moment from "moment";

const Index = () => {
  const history = useNavigate();
  const [form] = Form.useForm();
  const { id } = useParams();
  const [AdsData, setAdsData] = useState([]);
  const [webisteData, setwebisteData] = useState([]);
  const [openAdModal, setopenAdModal] = useState(false);
  const [loadingAds, setloadingAds] = useState(false);

  useEffect(() => {
    setloadingAds(true);
    api
      .get(`/websites/${id}`)
      .then((res) => {
        setwebisteData(res.data);
      })
      .catch((err) => {
        console.log(err);
        setwebisteData([]);
      });
    api
      .get(`/websites/${id}/ads`)
      .then((res) => {
        setAdsData(res.data);
        setloadingAds(false);
      })
      .catch((err) => {
        setloadingAds(false);
        setAdsData([]);
      });
  }, []);
  const CreateAd = async (values) => {
    try {
      await api.post(`/websites/${id}/ad`, {
        ...values,
        website_id: id,
        start_date: moment(values.start_date).format("YYYY-MM-DD"),
        end_date: moment(values.end_date).format("YYYY-MM-DD"),
      });
      setopenAdModal(false);
      form.resetFields();
      api
        .get(`/websites/${id}/ads`)
        .then((res) => {
          setAdsData(res.data);
          setloadingAds(false);
        })
        .catch((err) => {
          setloadingAds(false);
          setAdsData([]);
        });
    } catch (err) {
      console.log(err);
    }
  };
  const beforeUpload = (file) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      form.setFieldsValue({
        url: reader.result,
      });
    };
    reader.readAsDataURL(file);
    return false;
  };
  return (
    <div>
      <section>
        <div className="page-title-back-btn-outer">
          <div
            className="back-btn  pointer-cursor"
            onClick={() => history("/website-list")}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
              <path d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z" />
            </svg>
            Back
          </div>
        </div>
      </section>
      <header
        className="d-flex justify-content-between"
        style={{
          alignItems: "center",
        }}
      >
        <div className="page-title">Ads for {webisteData.name}</div>
        <div>
          <Button
            style={{
              background: "#25a2b7",
              borderRadius: "5px",
              color: "#fff",
              height: "38px",
              paddingLeft: "20px",
              paddingRight: "20px",
              margin: "0px 5px",
              fontSize: "16px",
            }}
            onClick={() => setopenAdModal(true)}
          >
            Create Ad
          </Button>
        </div>
      </header>
      {!loadingAds && AdsData.length === 0 && (
        <section className="mt-3">
          <Empty />
        </section>
      )}
      {loadingAds && <div>Loading...</div>}
      {!loadingAds &&
        AdsData.length > 0 &&
        AdsData.map((ad) => {
          return (
            <div
              key={ad._id}
              className="d-flex"
              style={{
                border: "1px solid #ccc",
                padding: "10px",
                borderRadius: "5px",
                margin: "10px 0px",
              }}
            >
              <aside
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={ad.url}
                  style={{
                    width: "100px",
                    height: "100px",
                    objectFit: "cover",
                    borderRadius: "5px",
                    marginRight: "10px",
                  }}
                  alt="ad"
                />
              </aside>
              <aside
                style={{
                  width: "100%",
                }}
              >
                <div className="d-flex justify-content-between">
                  <div
                    style={{
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                  >
                    {" "}
                    {ad.name}
                  </div>
                  <section>
                    {" "}
                    <Button
                      style={{
                        background: "#25a2b7",
                        borderRadius: "5px",
                        color: "#fff",
                        height: "38px",
                        paddingLeft: "20px",
                        paddingRight: "20px",
                        margin: "0px 5px",
                        fontSize: "16px",
                      }}
                      // onClick={() => setopenAdModal(true)}
                    >
                      Edit
                    </Button>
                    <Button
                      style={{
                        background: "#25a2b7",
                        borderRadius: "5px",
                        color: "#fff",
                        height: "38px",
                        paddingLeft: "20px",
                        paddingRight: "20px",
                        margin: "0px 5px",
                        fontSize: "16px",
                      }}
                      // onClick={() => setopenAdModal(true)}
                    >
                      Delete
                    </Button>
                  </section>
                </div>
                <div>{moment(ad.start_date).format("ll")}</div>
                <div>{moment(ad.end_date).format("ll")}</div>
                <div
                  style={{
                    fontWeight: "bold",
                    marginTop: "10px",
                    color: ad.status === "active" ? "green" : "red",
                  }}
                >
                  {ad.status.charAt(0).toUpperCase() + ad.status.slice(1)}
                </div>
              </aside>
            </div>
          );
        })}
      <Modal
        title="Create Ad"
        centered
        open={openAdModal}
        onCancel={() => setopenAdModal(false)}
        footer={false}
      >
        <Form layout="vertical" onFinish={CreateAd} form={form}>
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                message: "Please input the name!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="url"
            label="URL"
            rules={[
              {
                required: true,
                message: "Please input the url!",
              },
            ]}
          >
            <Upload beforeUpload={beforeUpload} maxCount={1}>
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item>
          <Form.Item
            name="start_date"
            label="Start Date"
            rules={[
              {
                required: true,
                message: "Please input the start date!",
              },
            ]}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item
            name="end_date"
            label="End Date"
            rules={[
              {
                required: true,
                message: "Please input the end date!",
              },
            ]}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item
            name="status"
            label="Status"
            rules={[
              {
                required: true,
                message: "Please select the status!",
              },
            ]}
          >
            <Select>
              <Select.Option value="active">Active</Select.Option>
              <Select.Option value="inactive">Inactive</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Index;
